import { Check, MoreVertical, Trash2 } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppState } from "redux/store";
import { addUserProperties } from "redux/task/actionCreators";
import { selectUserProperties } from "redux/task/selectors";
import { randomColor } from "shared/utils/transformDataDynamic";

const colorOptions = [
  { name: "Default", value: "#FFFFFF", bgClass: "bg-white" },
  { name: "Gray", value: "#F3F4F6", bgClass: "bg-gray-100" },
  { name: "Brown", value: "#FEF3C7", bgClass: "bg-amber-100" },
  { name: "Orange", value: "#FFEDD5", bgClass: "bg-orange-100" },
  { name: "Yellow", value: "#FEF9C3", bgClass: "bg-yellow-100" },
  { name: "Green", value: "#DCFCE7", bgClass: "bg-green-100" },
  { name: "Blue", value: "#DBEAFE", bgClass: "bg-blue-100" },
  { name: "Purple", value: "#F3E8FF", bgClass: "bg-purple-100" },
  { name: "Pink", value: "#FCE7F3", bgClass: "bg-pink-100" },
  { name: "Red", value: "#FEE2E2", bgClass: "bg-red-100" },
];

export const ThreeDots = ({
  option,
  statusList,
  setStatusList,
  onDelete,
  handleUpdateExistingProperty
}: any) => {
  const [showEditor, setShowEditor] = useState(false);
  const [editText, setEditText] = useState(option.label);
  const [selectedColor, setSelectedColor] = useState(option.backgroundColor);
  const editorRef = useRef(null);
  const dotsRef = useRef(null);
  const userProperties = useAppState(selectUserProperties);
    const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        editorRef.current &&
        !editorRef.current.contains(event.target) &&
        dotsRef.current &&
        !dotsRef.current.contains(event.target)
      ) {
        handleSave();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editText, selectedColor]);

  const handleSave = () => {
    if (editText !== option.label || selectedColor !== option.backgroundColor) {
      const updatedList = statusList.map((item) =>
        item.label === option.label
          ? { ...item, label: editText, backgroundColor: selectedColor }
          : item
      );
      // setStatusList(updatedList);

      handleUpdateExistingProperty(option.label, editText, selectedColor);
    }
    setShowEditor(false);
  };

  const handleDelete = () => {
    const updatedList = statusList.filter(
      (item) => item.label !== option.label
    );
    setStatusList(updatedList);
    setShowEditor(false);
    if (onDelete) onDelete(option);
  };

  const toggleEditor = (e) => {
    e.stopPropagation();
    setShowEditor(!showEditor);
  };

  return (
    <div className="relative ml-auto">
      <div
        ref={dotsRef}
        className="flex flex-col items-center justify-center w-8 h-8 cursor-pointer hover:bg-gray-100 rounded-full"
        onClick={toggleEditor}
      >
        <MoreVertical size={16} className="text-gray-500" />
      </div>

      {showEditor && (
        <div
          ref={editorRef}
          className="absolute right-0 top-full mt-1 bg-white rounded-lg shadow-lg border border-gray-200 w-72 z-50"
        >
          <div className="p-3 border-b">
            <input
              type="text"
              value={editText}
              onChange={(e) => setEditText(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div
            className="p-3 flex items-center gap-2 border-b cursor-pointer hover:bg-gray-50"
            onClick={handleDelete}
          >
            <Trash2 className="text-gray-500" size={16} />
            <span className="text-gray-700 font-medium">Delete</span>
          </div>

          <div className="p-3">
            <div className="text-gray-500 mb-2">Colors</div>
            <div className="grid grid-cols-2 gap-2">
              {colorOptions.map((color) => (
                <div
                  key={color.name}
                  onClick={() => setSelectedColor(color.value)}
                  className="flex items-center gap-2 p-2 rounded-md hover:bg-gray-100 cursor-pointer"
                >
                  <div
                    className={`w-6 h-6 rounded-md border`}
                    style={{
                      backgroundColor: color.value,
                    }}
                  ></div>
                  <span>{color.name}</span>
                  {selectedColor === color.value && (
                    <Check className="ml-auto text-gray-600" size={16} />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
