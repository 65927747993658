import { Modal, Button } from "antd";
import { SaveOutlined, PlusOutlined } from "@ant-design/icons";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TaskSet } from "models/task.model";
import { TaskSetCard } from "./TaskSetCard";
import PopupSkeleton from "../PopupSkeleton/popupSkeleton";
import { useDispatch } from "react-redux";
import { getTasks, importTask, saveTemplate } from "redux/task/actionCreators";
import { useAppState } from "redux/store";
import {
  selectActiveProject,
  selectActiveTask,
  selectAllViews,
  selectTaskIsLoading,
} from "redux/task/selectors";
import { setDrawerVisible } from "redux/task/taskSlice";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {
  selectInstallerDetails,
  selectUserDetails,
} from "redux/user/selectors";

interface TaskTemplatesModalProps {
  isOpen: boolean;
  onClose: () => void;
  list: any[];
}

export function TaskTemplatesModal({
  isOpen,
  onClose,
  list,
}: TaskTemplatesModalProps) {
  const dispatch = useDispatch();
  const activeProject = useAppState(selectActiveProject);
  const isLoading = useAppState(selectTaskIsLoading);
  const userDetails = useAppState(selectUserDetails);
  const views = useAppState(selectAllViews);
  const activeView = useAppState(selectActiveTask);
  const installerDetails = useAppState(selectInstallerDetails);

  const { t } = useTranslation();

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleAddTask = async (projectId: string) => {
    try {
      const activeViewDetails = views.find((v) => v._id === activeView);
      const action = await dispatch(
        importTask({ viewId: projectId, projectId: activeProject || "" })
      );

      if (action?.meta.requestStatus === "fulfilled") {
        dispatch(
          getTasks({
            projectId: activeProject,
            filters: activeViewDetails?.filters || [],
            hiddenProperties: activeViewDetails?.hiddenProperties || [],
            groupBy: activeViewDetails?.groupBy || [],
            order: activeViewDetails?.order || [],
            companyName: installerDetails?.companyName,
          })
        );
      }
    } catch (error) {
      console.error("Error importing task:", error);
    }
  };

  const handleSaveTaskSet = () => {
    dispatch(
      saveTemplate({
        projectId: activeProject || "",
        name: `Task set ${(Math.random() * 100).toFixed()}`,
        userId: userDetails?._id || "",
      })
    );
  };

  const handleAddNewTask = () => {
    onClose();
    dispatch(setDrawerVisible(true));
  };

  return (
    <PopupSkeleton
      open={isOpen}
      handleClose={onClose}
      styless={{ minWidth: "800px" }}
    >
      <>
        {isLoading && (
          <div className="absolute inset-0 flex justify-center items-center">
            <CircularProgress />
          </div>
        )}
        <div className="space-y-8 p-8">
          <h2 className="text-2xl font-semibold text-[#2D4764]">
            {t("Load task set templates")}
          </h2>
          <div className="slider-container px-2">
            <Slider {...sliderSettings}>
              {list?.map((taskSet) => (
                <div key={taskSet._id}>
                  <TaskSetCard
                    taskSet={taskSet}
                    handleAddTask={handleAddTask}
                  />
                </div>
              ))}
            </Slider>
          </div>

          <div className="space-y-4">
            <div className="text-2xl font-semibold text-[#2D4764]">
              {t("Save new task set")}
            </div>
            <div className="space-y-3 my-4">
              <Button
                icon={<SaveOutlined />}
                size="large"
                className="w-full flex items-center text-left bg-white hover:bg-gray-50 border-2 rounded-2xl h-auto py-3"
                style={{ marginRight: "10px" }}
                onClick={handleSaveTaskSet}
              >
                <span className="ml-2 text-lg text-[#2D4764]">
                  {t("Save current task set")}
                </span>
              </Button>
              <Button
                icon={<PlusOutlined />}
                size="large"
                className="w-full flex items-center text-left bg-white hover:bg-gray-50 border-2 rounded-2xl h-auto py-3"
              >
                <span
                  className="ml-2 text-lg text-[#2D4764]"
                  onClick={handleAddNewTask}
                >
                  {t("Add new task")}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </>
    </PopupSkeleton>
  );
}
