import { IApiError } from "models/apiError.model";
import { IPagination } from "models/pagination.model";

export const SAVED_PROJECT_SLICE_NAME = "savedProject";

export interface savedProjectState {
  savedProject: any | null;
  activeProjectViewSection: 'customer_target' | 'file_manager';
  isLoading: boolean;
  error: IApiError | null;
  pagination: IPagination | null;
  maxMin: {
    totalCost: number;
    totalConsumption: number;
  } | null;
}

export const initialState: savedProjectState = {
  savedProject: [],
  isLoading: false,
  error: null,
  pagination: null,
  maxMin: null,
  activeProjectViewSection: 'customer_target' // customer_target || file_manager
};
