import { IPagination } from "./pagination.model";

export interface IGetSavedProjectAllResponse {
  data: {
    docs: any;
    pagination: IPagination;
    searchAbleFields: any;
    maxMin: any;
  };
}

export interface IGetAllSavedProjectDTO {
  shortId?: string;
  page: number;
  limit: number;
  fromView?: boolean;
}

export enum SavedProjectEndpoints {
  SAVED_PROJECT = "/saved-project",
  INSTALLER = "/installer",
  SEARCH = "/search",
}

export interface IPaginationProps {
  page: number;
  limit: number;
}

export const getSavedProjectEndpoints = ({
  page,
  limit,
}: IPaginationProps) => ({
  GET_ALL_SAVED_PROJECT: `${SavedProjectEndpoints.SAVED_PROJECT}${SavedProjectEndpoints.INSTALLER}${SavedProjectEndpoints.SEARCH}?page=${page}&limit=${limit}`,
});
