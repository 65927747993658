import { RootState } from "redux/store";
import { TASK_SLICE_NAME, TaskState } from "./models";
import { createSelector } from "@reduxjs/toolkit";

const taskSelector = (state: RootState): TaskState => state[TASK_SLICE_NAME];

export const selectTaskIsLoading = createSelector(
  taskSelector,
  (task: TaskState) => task.isLoading
);

export const selectAllViews = createSelector(
  taskSelector,
  (task: TaskState) => task.views
);

export const selectTasks = createSelector(
  taskSelector,
  (task: TaskState) => task.tasks
);

export const selectActiveTask = createSelector(
  taskSelector,
  (task: TaskState) => task.activeTask
);

export const selectColumns = createSelector(
  taskSelector,
  (task: TaskState) => task.columns
);

export const selectData = createSelector(
  taskSelector,
  (task: TaskState) => task.data
);

export const selectFilteredData = createSelector(
  taskSelector,
  (task: TaskState) => task.filteredData
);

export const selectSkipReset = createSelector(
  taskSelector,
  (task: TaskState) => task.skipReset
);

export const selectActiveFilters = createSelector(
  [taskSelector, selectActiveTask, selectAllViews],
  (task: TaskState, activeTask, views) => {
    const matchedView =
      views.find((view) => view._id === activeTask)?.filters || [];
    return matchedView;
  }
);

export const selectSelectedOptionIndex = createSelector(
  taskSelector,
  (task: TaskState) => task.selectedOptionIndex
);

export const selectIsMenuOpen = createSelector(
  taskSelector,
  (task: TaskState) => task.isMenuOpen
);

export const selectShowFilters = createSelector(
  taskSelector,
  (task: TaskState) => task.showFilters
);

export const selectDrawerVisible = createSelector(
  taskSelector,
  (task: TaskState) => task.drawerVisible
);

export const selectModalPosition = createSelector(
  taskSelector,
  (task: TaskState) => task.modalPosition
);

export const selectIsViewCreateModalOpen = createSelector(
  taskSelector,
  (task: TaskState) => task.isViewCreateModalOpen
);

export const selectSelectedTask = createSelector(
  taskSelector,
  (task: TaskState) => task.selectedTask
);

export const selectGroupBy = createSelector(
  [taskSelector, selectActiveTask, selectAllViews],
  (task: TaskState, activeTask, views) => {
    const matchedView =
      views.find((view) => view._id === activeTask)?.groupBy?.[0] || null;
    return matchedView;
  }
);

export const selectIsMultiTaskOpen = createSelector(
  taskSelector,
  (task: TaskState) => task.miltiTaskDrawer
);

export const selectActiveLayout = createSelector(
  [taskSelector, selectActiveTask, selectAllViews],
  (task: TaskState, activeTask, views) => {
    const matchedView =
      views.find((view) => view._id === activeTask)?.viewType || "table";
    return matchedView;
  }
);

export const selectHiddenProperties = createSelector(
  [taskSelector, selectActiveTask, selectAllViews],
  (task: TaskState, activeTask, views) => {
    const matchedView =
      views.find((view) => view._id === activeTask)?.hiddenProperties || [];
    return matchedView;
  }
);

export const selectActiveProject = createSelector(
  taskSelector,
  (task: TaskState) => task.activeProject
);

export const selectActiveSavedProject = createSelector(
  taskSelector,
  (task: TaskState) => task.activeSavedProject
);

export const selectImportableProjects = createSelector(
  taskSelector,
  (task: TaskState) => task.importableProjects
);

export const selectProjectIsLoading = createSelector(
  taskSelector,
  (task: TaskState) => task.projectIsLoading
);

export const selectSortingProperties = createSelector(
  taskSelector,
  (task: TaskState) => task.sortingProperties
);

export const selectUserProperties = createSelector(
  taskSelector,
  (task: TaskState) => task.userProperties
);

export const selectActiveSorting = createSelector(
  [taskSelector, selectActiveTask, selectAllViews],
  (task: TaskState, activeTask, views) => {
    const matchedView =
      views.find((view) => view._id === activeTask)?.sort || [];
    return matchedView;
  }
);
