import { DownOutlined, PlusOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Tag, Typography } from "antd";
import { t } from "i18next";
import { ListItem } from "models/task.model";
import React, { useState } from "react";

const { Text } = Typography;

const AddList = () => {
  const [lists, setLists] = useState<ListItem[]>([]);
  const [isAddingList, setIsAddingList] = useState(false);
  const [newListName, setNewListName] = useState("");
  const [listsExpanded, setListsExpanded] = useState(true);

  const handleAddList = () => {
    if (newListName.trim()) {
      const newList: ListItem = {
        id: Date.now().toString(),
        name: newListName.trim(),
      };
      setLists([...lists, newList]);
      setNewListName("");
      setIsAddingList(false);
    }
  };

  const handleRemoveList = (id: string) => {
    setLists(lists.filter((list) => list.id !== id));
  };

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <div
          className="cursor-pointer flex items-center"
          onClick={() => setListsExpanded(!listsExpanded)}
        >
          {listsExpanded ? (
            <DownOutlined style={{ color: "#262626" }} />
          ) : (
            <UpOutlined style={{ color: "#262626" }} />
          )}
          <Text strong className="ml-2">
            {t("Lists")}
          </Text>
        </div>
        <Button
          type="text"
          icon={<PlusOutlined style={{ color: "#8c8c8c" }} />}
          style={{ ...buttonStyle, color: "#8c8c8c" }}
          onClick={() => setIsAddingList(true)}
        >
          {t("Add to list")}
        </Button>
      </div>
      <Modal
        title={t("Add to List")}
        visible={isAddingList}
        onOk={handleAddList}
        onCancel={() => setIsAddingList(false)}
        okText="Add"
        cancelText="Cancel"
        okButtonProps={{
          style: { backgroundColor: "#1890ff", borderColor: "#1890ff" },
        }}
      >
        <Form layout="vertical">
          <Form.Item label="List Name" required>
            <Input
              value={newListName}
              onChange={(e) => setNewListName(e.target.value)}
              placeholder="Enter list name"
              style={inputStyle}
            />
          </Form.Item>
        </Form>
      </Modal>

      {listsExpanded && (
        <div className="ml-6" style={{ color: "#8c8c8c" }}>
          {lists.length === 0 ? (
            <Text>{t("This record has not been added to any lists")}</Text>
          ) : (
            lists.map((list) => (
              <Tag
                key={list.id}
                closable
                onClose={() => handleRemoveList(list.id)}
                style={{ ...tagStyle, marginBottom: 8 }}
              >
                {list.name}
              </Tag>
            ))
          )}
        </div>
      )}
    </>
  );
};

export default AddList;

export const inputStyle: React.CSSProperties = {
  backgroundColor: "white",
  borderColor: "#d9d9d9",
  color: "#262626",
};

export const buttonStyle: React.CSSProperties = {
  border: "none",
  boxShadow: "none",
};

export const tagStyle: React.CSSProperties = {
  backgroundColor: "#f0f0f0",
  borderColor: "#d9d9d9",
  color: "#262626",
};
