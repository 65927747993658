import type React from "react";
import { useState, useEffect, useRef } from "react";
import { Button, Drawer } from "antd";
import {
  CloseOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
} from "@ant-design/icons";

interface ResizableDrawerProps {
  visible: boolean;
  onClose: () => void;
  isFullScreen: boolean;
  children: React.ReactNode;
  setIsFullScreen: (isFullScreen: boolean) => void;
}

export const ResizableDrawer: React.FC<ResizableDrawerProps> = ({
  visible,
  onClose,
  isFullScreen,
  children,
  setIsFullScreen,
}) => {
  const [drawerWidth, setDrawerWidth] = useState(500);
  const [isDragging, setIsDragging] = useState(false);
  const dragHandleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        const newWidth = window.innerWidth - e.clientX;
        setDrawerWidth(
          Math.max(300, Math.min(newWidth, window.innerWidth - 100))
        );
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      width={isFullScreen ? "100vw" : drawerWidth}
      title={null}
      getContainer={false}
      style={{
        position: "absolute",
        left: "auto",
        right: 0,
      }}
      closeIcon={
        <div className="flex items-center gap-2">
          <Button
            type="text"
            icon={
              isFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />
            }
            onClick={(e) => {
              e.stopPropagation();
              setIsFullScreen(!isFullScreen);
            }}
          />
          <Button type="text" onClick={onClose} icon={<CloseOutlined />} />
        </div>
      }
    >
      <div
        ref={dragHandleRef}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          bottom: 0,
          width: "5px",
          cursor: "ew-resize",
        }}
        onMouseDown={() => setIsDragging(true)}
      />
      {children}
    </Drawer>
  );
};
