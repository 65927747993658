import { IPagination } from "./pagination.model";
import { IQuote } from "./quotes.model";
import { IUserDetails } from "./user.model";

export interface EnergyProductionParams {
  roofPitch: number;
  increasedCapacityFromAdminPanel: number;
  productionReducedDueToShade: number;
  productionByBatterySimulation: number;
}

export interface Images {
  houseTop: HouseTop;
  houseBottom: HouseBottom;
  electricMeter: ElectricMeter;
  _id: string;
}

export interface HouseTop {
  imageURLs: string[];
  description: string;
}

export interface HouseBottom {
  imageURLs: any[];
  description: string;
}

export interface ElectricMeter {
  imageURLs: string[];
  description: string;
}

export interface IProject {
  _id: string;
  quoteID: string;
  profile: string;
  energyProductionParams: EnergyProductionParams;
  uid: IUserDetails;
  version: number;
  components: IComponents;
  images: Images;
  household: any;
  financialDetails: any;
  vendor: any;
  pid: string;
  payment: any;
  geohash: string;
  __v: number;
  shortId: number;
  secret: string;
  accessCount: number;
  createdAt: string;
  pdfUrl: string;
  shareProjectLinks: any[];
  updatedAt: string;
  quote: IQuote;
}
export interface IMatchedQuote {
  _id: string;
  iid: string;
  isPaymentDone: boolean;
  isProjectRealized: boolean;
  realizationWeek: any;
  discount?: number;
  realizationWeekShort: any;
  condition?: string;
  finalPrice: number;
  pdfUrl: string;
  selectedProducts?: [
    {
      id: string;
      quantity: number;
    }
  ];
  selectedConstants?: [string];
  offerDescription?: string;
  pid: string;
  status: string;
  __v: number;
  project: IProject;
  address: string;
  complete: string;
  country: string;
  state: string;
  postcode: string;
  city: string;
  consumption: number;
  totalCost: number;
  rating: number;
  projectType: string;
  isDetailedOffer?: boolean;
  finalDetailedPrice?: number
  roofType: string;
  buildingType: string;
  offers?: any
}

export interface IGetMatchedProjectAllResponse {
  data: {
    docs: IMatchedQuote[];
    pagination: IPagination;
    searchAbleFields: any;
    maxMin: any;
  };
}

export interface IGetAllMatchedProjectDTO {
  shortId?: string;
  page: number;
  limit: number;
  projectStatus?: string;
}

export interface IUpdateMatchedProject {
  id: string;
  realizationWeek?: string | null;
  realizationWeekShort?: string | null;
  finalPrice?: number;
  discount?: number;
  pdfUrl?: string;
  projectStatus?: string;
  isDetailedOffer?: boolean;
  condition?: string;
  visitDate?: string;
  files?: string[]
  pid?: string;
  offerDescription?: string;
  finalDetailedPrice?: number;
  selectedProducts?: [
    {
      id: string;
      quantity: number;
    }
  ];
  selectedConstants?: [string];
  offers?: any
}

export enum MatchedProjectEndpoints {
  MATCHED_PROJECT = "/matched",
  INSTALLER = "/installer",
  SEARCH = "/search",
  UPDATE_COMPONENTS = "/saved-project/update"
}

export interface IPaginationProps {
  page?: number;
  limit?: number;
  id?: string;
}

export const getMatchedProjectEndpoints = ({
  page,
  limit,
  id,
}: IPaginationProps) => ({
  GET_ALL_MATCHED_PROJECT: `${MatchedProjectEndpoints.MATCHED_PROJECT}${MatchedProjectEndpoints.INSTALLER}${MatchedProjectEndpoints.SEARCH}?page=${page}&limit=${limit}`,
  UPDATE_MATCHED_PROJECT: `${MatchedProjectEndpoints.MATCHED_PROJECT}/${id}`,
  UPDATE_COMPONENTS: `${MatchedProjectEndpoints.UPDATE_COMPONENTS}/${id}`,
});

export interface IComponents {
  solar_panel: SolarPanel;
  inverter: {
    items: Inverter[],
    preferences: Preferences2;
  };
  wallBox: WallBox;
  battery: Battery;
  heatpump: Heatpump;
  cars: any[];
  waterHeating: boolean;
  _id: string;
}

export interface SolarPanel {
  item: string;
  isExcludeCost: boolean;
  extendedWarranty: boolean;
  quantity: number;
  preferences: Preferences;
}

export interface Preferences {
  "Made in Germany": boolean;
  "Glas / Glas Module": boolean;
  "High performance module >370 Wp": boolean;
  "Full black": boolean;
  "Data visualization": boolean;
  "Load management": boolean;
  "made in europe": boolean;
  hybrid: boolean;
  Extendible: boolean;
  "Emergency Power": boolean;
  Display: boolean;
  Authorisation: boolean;
  "Schnellladung 22KW": boolean;
  App: boolean;
  "Air/Water-Heatpump": boolean;
  "App for heat management": boolean;
  "Warm water heating": boolean;
}

export interface Inverter {
  item: string;
  isExcludeCost: boolean;
  extendedWarranty: boolean;
  quantity: number;
}

export interface Preferences2 {
  "Made in Germany": boolean;
  "Glas / Glas Module": boolean;
  "High performance module >370 Wp": boolean;
  "Full black": boolean;
  "Data visualization": boolean;
  "Load management": boolean;
  "made in europe": boolean;
  hybrid: boolean;
  Extendible: boolean;
  "Emergency Power": boolean;
  Display: boolean;
  Authorisation: boolean;
  "Schnellladung 22KW": boolean;
  App: boolean;
  "Air/Water-Heatpump": boolean;
  "App for heat management": boolean;
  "Warm water heating": boolean;
}

export interface WallBox {
  item: any;
  isExcludeCost: boolean;
  extendedWarranty: boolean;
  quantity: number;
  preferences: Preferences3;
}

export interface Preferences3 {
  "Made in Germany": boolean;
  "Glas / Glas Module": boolean;
  "High performance module >370 Wp": boolean;
  "Full black": boolean;
  "Data visualization": boolean;
  "Load management": boolean;
  "made in europe": boolean;
  hybrid: boolean;
  Extendible: boolean;
  "Emergency Power": boolean;
  Display: boolean;
  Authorisation: boolean;
  "Schnellladung 22KW": boolean;
  App: boolean;
  "Air/Water-Heatpump": boolean;
  "App for heat management": boolean;
  "Warm water heating": boolean;
}

export interface Battery {
  item: string;
  isExcludeCost: boolean;
  extendedWarranty: boolean;
  quantity: number;
  preferences: Preferences4;
}

export interface Preferences4 {
  "Made in Germany": boolean;
  "Glas / Glas Module": boolean;
  "High performance module >370 Wp": boolean;
  "Full black": boolean;
  "Data visualization": boolean;
  "Load management": boolean;
  "made in europe": boolean;
  hybrid: boolean;
  Extendible: boolean;
  "Emergency Power": boolean;
  Display: boolean;
  Authorisation: boolean;
  "Schnellladung 22KW": boolean;
  App: boolean;
  "Air/Water-Heatpump": boolean;
  "App for heat management": boolean;
  "Warm water heating": boolean;
}

export interface Heatpump {
  item: any;
  isExcludeCost: boolean;
  extendedWarranty: boolean;
  quantity: number;
  configuration: Configuration;
  preferences: Preferences5;
}

export interface Configuration {
  consumptionHeatpump: number;
  totalAreaToBeHeated: number;
  energyDemand: number;
  buildingStandard: number;
  heatDistribution: string;
  oilGasCost: number;
  heatingCapacity: number;
  electricityCostHeatpump: number;
  heatLoadBuilding: number;
  blockingTimes: number;
  heatpump_dimension: number;
  solarPowerForHeatpump: number;
}

export interface Preferences5 {
  "Made in Germany": boolean;
  "Glas / Glas Module": boolean;
  "High performance module >370 Wp": boolean;
  "Full black": boolean;
  "Data visualization": boolean;
  "Load management": boolean;
  "made in europe": boolean;
  hybrid: boolean;
  Extendible: boolean;
  "Emergency Power": boolean;
  Display: boolean;
  Authorisation: boolean;
  "Schnellladung 22KW": boolean;
  App: boolean;
  "Air/Water-Heatpump": boolean;
  "App for heat management": boolean;
  "Warm water heating": boolean;
}
