import { FieldType } from "models/task.model";
import { FRONTEND_URL } from "shared/constants/common";

type Row = {
  [key: string]: any;
};

type Option = {
  label: string;
  backgroundColor: string;
};

type Column = {
  id: string;
  label: string;
  icon?: string;
  accessor?: string;
  minWidth?: number;
  width?: number;
  dataType: FieldType;
  options: Option[];
  disableResizing?: boolean;
};

type TableData = {
  columns: Column[];
  data: Row[];
  skipReset: boolean;
};

interface OriginalDataItem {
  id: string;
  label: string;
  accessor: string;
  dataType: FieldType;
  icon: string;
  options: Option[];
}

export function transformDataDynamic(
  inputData: any[],
  isProject: boolean = false
): TableData {
  if (inputData.length === 0) {
    return {
      columns: [
        {
          id: "999999",
          label: "+",
          width: 20,
          dataType: FieldType.Null,
          options: [],
          disableResizing: true,
        },
      ],
      data: [],
      skipReset: false,
    };
  }

  console.log(inputData, "inputData");

  function getValue(obj: any, path: string): any {
    if (path === "action") {
      return `${FRONTEND_URL}/application/saved-${obj.shortId}/${obj.version}admin=true`;
    }

    return path
      .split(".")
      .reduce(
        (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
        obj
      );
  }

  if (isProject) {
    const visibleFields = [
      { key: "address", path: "address" },
      { key: "city", path: "city" },
      { key: "shortId", path: "shortId" },
      { key: "version", path: "version" },
      { key: "projectStatus", path: "projectStatus", type: FieldType.Select },
      { key: "roofType", path: "quote.roofType" },
      {
        key: "buildingType",
        path: "quote.buildingType",
        type: FieldType.Select,
      },
      { key: "status", path: "quote.status", type: FieldType.Select },
      { key: "people", path: "household.people" },
      {
        key: "pricePerKwh",
        path: "financialDetails.pricePerKwh",
        type: FieldType.Number,
      },
      { key: "feedInTariff", path: "financialDetails.feedInTariff" },
      {
        key: "installerPartner",
        path: "quote.installerPartner",
        type: FieldType.MultiSelect,
      },
      { key: "consumption", path: "consumption", type: FieldType.Number },
      { key: "totalCost", path: "totalCost", type: FieldType.Number },
      { key: "firstName", path: "firstName" },
      { key: "lastName", path: "lastName" },
      { key: "email", path: "email" },
      { key: "rating", path: "rating" },
      { key: "action", path: "action", type: FieldType.Button },
      {
        key: "projectDetails",
        path: "projectDetails",
        type: FieldType.Button,
      },
      {
        key: "responsiblePerson",
        path: "responsibleUser.firstName",
        type: FieldType.Person,
      },
      {
        key: "uploadedInstallerImages",
        path: "uploadedInstallerImages",
        isHidden: true,
        type: FieldType.Files,
      },
      {
        key: "_id",
        path: "_id",
        isHidden: true,
      },
      { key: "quoteId", path: "quoteID", isHidden: true },
    ];

    const columns: Column[] = visibleFields
      .filter((f) => !f.isHidden)
      .map(({ key, path, type }) => ({
        id: key,
        label: key,
        accessor: key,
        dataType: type || FieldType.Text,
        icon: inputData[0][key]?.icon,
        options:
          type === FieldType.Select || type === FieldType.MultiSelect
            ? Array.from(
                new Set(
                  inputData.flatMap((item) => {
                    return Array.isArray(item[key])
                      ? item[key]
                      : [item[key]?.value || (item[key] as string)];
                  })
                )
              ).map((val: string) => ({
                label: val,
                backgroundColor: randomColor(),
              }))
            : [],
      }));

    columns.push({
      id: "999999",
      label: "+",
      width: 20,
      dataType: FieldType.Null,
      options: [],
      disableResizing: true,
    });

    const data: Row[] = inputData.map((item) => {
      const row: Row = {};
      visibleFields.forEach(({ key, path }) => {
        row[key] = getValue(item, path) || "";
      });
      return row;
    });

    return { columns, data, skipReset: false };
  } else {
    const visibleFields = Array.from(
      new Set(
        inputData.flatMap((item) =>
          Object.keys(item).filter(
            (key) =>
              key !== "_id" &&
              key !== "viewId" &&
              key !== "__v" &&
              key !== "createdAt" &&
              key !== "updatedAt" &&
              key !== "projectId" &&
              key !== "fromRecord" &&
              key !== "companyName"
          )
        )
      )
    );

    const columns: Column[] = visibleFields.map((key) => {
      if (
        typeof inputData[0][key] === "object" &&
        inputData[0][key]?.value !== undefined
      ) {
        const dataType = inputData[0][key]?.type;
        return {
          id: key,
          label: key,
          accessor: key,
          dataType,
          icon: inputData[0][key]?.icon,
          options:
            dataType === FieldType.Select || dataType === FieldType.MultiSelect
              ? inputData.flatMap((item) =>
                  Array.isArray(item[key]?.value)
                    ? item[key]?.value.map((val: string) => ({
                        label: val,
                        backgroundColor: randomColor(),
                      }))
                    : [
                        {
                          label: item[key]?.value,
                          backgroundColor: randomColor(),
                        },
                      ]
                )
              : [],
        };
      }
      return {
        id: key,
        label: key,
        accessor: key,
        dataType: FieldType.Text,
        options: [],
      };
    });

    columns.push({
      id: "999999",
      label: "+",
      width: 20,
      dataType: FieldType.Null,
      options: [],
      disableResizing: true,
    });

    const data: Row[] = inputData.map((item) => {
      const row: Row = {};
      Object.keys(item).forEach((key) => {
        if (key !== "viewId") {
          row[key] =
            item[key]?.value !== undefined ? item[key]?.value : item[key];
        }
      });
      return row;
    });

    return { columns, data, skipReset: false };
  }
}

export const transformData = ({
  projectId,
  viewId,
  data,
  preData = {},
  id,
  companyName,
}: {
  projectId?: string;
  viewId?: string;
  data: OriginalDataItem[];
  preData?: any;
  id?: string;
  companyName?: string;
}): any => {
  const transformedEntries = data.map((item) => {
    let value: null | string | string[] = "";

    // Handle select and multi-select
    if (item.dataType === "multi-select") {
      value = [];
    }

    return {
      [item.accessor]: {
        type: item.dataType === "checkbox" ? "boolean" : item.dataType,
        icon: item.icon,
        value: preData[item.accessor]?.value || value,
      },
    };
  });

  return {
    viewId,
    id,
    projectId,
    fromRecord: !!id,
    companyName,
    ...Object.assign({}, ...transformedEntries),
  };
};

export function randomColor() {
  // Using lower saturation (60-80%) and higher lightness (85-95%) for more subtle colors
  return `hsl(${Math.floor(Math.random() * 360)}, ${
    60 + Math.floor(Math.random() * 20)
  }%, ${85 + Math.floor(Math.random() * 10)}%)`;
}

export function transformReduxData(inputData: any): { [key: string]: string } {
  const transformedData: { [key: string]: string } = {};

  for (const key in inputData) {
    if (
      inputData[key] &&
      typeof inputData[key] === "object" &&
      "value" in inputData[key]
    ) {
      transformedData[key] = inputData[key].value;
    }
  }

  return transformedData;
}
