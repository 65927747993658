import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { USER_SLICE_NAME, UserState, initialState } from "./models";
import {
  getAllPanels,
  getAllUsers,
  getFreeDownloadCount,
  getUserDetails,
  loginUser,
  sendEmailToInstaller,
  updateDownloadCount,
} from "./actionCreators";
import appCookiesStorage from "shared/utils/appCookies";
import {
  AUTHORIZATION_TOKEN_EXPIRES,
  AUTHORIZATION_TOKEN_STORAGE_KEY,
  INSTALLER_CITY,
} from "shared/constants/common";
import showSuccessToast from "shared/components/SuccessToast/SuccessToast";
import showErrorToast from "shared/components/ErrorToast/ErrorToast";

export const userSlice = createSlice({
  name: USER_SLICE_NAME,
  initialState,
  reducers: {
    logOut() {
      appCookiesStorage.removeItem(AUTHORIZATION_TOKEN_STORAGE_KEY);
      appCookiesStorage.removeItem(INSTALLER_CITY);
      localStorage.clear();
      return { ...initialState };
    },
    setInstallerUser(state, action) {
      const { payload } = action;
      state.installerDetails = payload;
    },
    setConstant(state, action) {
      const { payload } = action;
      state.constants = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isFulfilled(loginUser), (state: UserState, action) => {
        const { payload } = action;

        if (!payload.data.token) {
          return;
        }

        state.user = payload.data.user;
        state.installerDetails = payload?.data?.installerDetails;
        state.constants = payload?.data?.installerDetails?.constants;

        appCookiesStorage.setItem(
          AUTHORIZATION_TOKEN_STORAGE_KEY,
          payload.data.token,
          { expires: AUTHORIZATION_TOKEN_EXPIRES }
        );

        appCookiesStorage.setItem(
          INSTALLER_CITY,
          payload.data.user?.installerCity,
          {
            expires: AUTHORIZATION_TOKEN_EXPIRES,
          }
        );
        state.isAuthorized = true;
        state.isLoading = false;
        showSuccessToast({ message: "Sie sind erfolgreich angemeldet" });
      })
      .addMatcher(
        isPending(
          loginUser,
          getUserDetails,
          updateDownloadCount,
          getFreeDownloadCount,
          sendEmailToInstaller,
          getAllPanels,
          getAllUsers
        ),
        (state: UserState) => {
          state.isLoading = true;
          state.error = null;
        }
      )
      .addMatcher(
        isRejected(loginUser, getUserDetails),
        (state: UserState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.isAuthorized = false;
          state.error = error;
          console.log(error, "error");
          showErrorToast({ message: "Ungültige E-Mail oder Passwort" });
        }
      )
      .addMatcher(isFulfilled(getUserDetails), (state: UserState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.user = payload.data;

        appCookiesStorage.setItem(INSTALLER_CITY, payload.data?.installerCity, {
          expires: AUTHORIZATION_TOKEN_EXPIRES,
        });
      })
      .addMatcher(
        isFulfilled(updateDownloadCount),
        (state: UserState, action) => {
          const { payload } = action;
          state.isLoading = false;
          state.user = payload.data;
        }
      )
      .addMatcher(
        isFulfilled(getFreeDownloadCount),
        (state: UserState, action) => {
          const { payload } = action;
          state.isLoading = false;
          state.freeDownloadCount = payload?.data[0]?.freeDownloadCountObj;
          state.properties = payload?.data[0]?.properties;
        }
      )
      .addMatcher(
        isRejected(
          updateDownloadCount,
          getFreeDownloadCount,
          sendEmailToInstaller,
          getAllPanels,
          getAllUsers
        ),
        (state: UserState) => {
          state.isLoading = false;
          showErrorToast({ message: "Something went wrong" });
        }
      )
      .addMatcher(isFulfilled(sendEmailToInstaller), (state: UserState) => {
        state.isLoading = false;
        showSuccessToast({ message: "Email sent successfully" });
      })
      .addMatcher(isFulfilled(getAllUsers), (state: UserState, action) => {
        state.isLoading = false;
        state.users = action.payload?.data?.docs;
      })
      .addMatcher(isFulfilled(getAllPanels), (state: UserState, action) => {
        state.isLoading = false;
        state.solarPanels = action.payload?.data;
      });
  },
});

export const { logOut, setInstallerUser, setConstant } = userSlice.actions;

export default userSlice.reducer;
