import { createAsyncThunk } from "@reduxjs/toolkit";
import { IApiError } from "models/apiError.model";
import { ITaskDTO, IUpdatetaskDTO, TASK_SLICE_NAME } from "./models";
import {
  ICreateProperties,
  ICreateView,
  IDeletePropertyRequest,
  IGetAllViewsResponse,
  IGetTasksResponse,
  IImporttask,
  ISaveTemplate,
  IUpdateHiddenProperty,
  IUpdatePropertyRequest,
  IUpdateTemplate,
  IUpdateView,
  IViewTask,
} from "models/task.model";
import { formatApiError } from "shared/utils";
import TaskAPI from "api/TaskAPI";

export const getAllViews = createAsyncThunk<
  IGetAllViewsResponse,
  string,
  { serializedErrorType: IApiError }
>(
  `${TASK_SLICE_NAME}/getAllViews`,
  async (moduleType: string) => {
    const response = await TaskAPI.getAllViews(moduleType);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const getTasks = createAsyncThunk<
  IGetTasksResponse,
  IViewTask,
  { serializedErrorType: IApiError }
>(
  `${TASK_SLICE_NAME}/getTasks`,
  async (data: IViewTask) => {
    const response = await TaskAPI.getTasks(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const createProperties = createAsyncThunk<
  any,
  ICreateProperties,
  { serializedErrorType: IApiError }
>(
  `${TASK_SLICE_NAME}/createProperties`,
  async (data: ICreateProperties) => {
    const response = await TaskAPI.createProperties(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const updateProperty = createAsyncThunk<
  any,
  IUpdatePropertyRequest,
  { serializedErrorType: IApiError }
>(
  `${TASK_SLICE_NAME}/updateProperty`,
  async (data: IUpdatePropertyRequest) => {
    const response = await TaskAPI.updateProperty(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const deleteProperty = createAsyncThunk<
  any,
  IDeletePropertyRequest,
  { serializedErrorType: IApiError }
>(
  `${TASK_SLICE_NAME}/deleteProperty`,
  async (data: IDeletePropertyRequest) => {
    const response = await TaskAPI.deleteProperty(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const createView = createAsyncThunk<
  any,
  ICreateView,
  { serializedErrorType: IApiError }
>(
  `${TASK_SLICE_NAME}/createView`,
  async (data) => {
    const response = await TaskAPI.createView(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const createTask = createAsyncThunk<
  any,
  ITaskDTO,
  { serializedErrorType: IApiError }
>(
  `${TASK_SLICE_NAME}/createTask`,
  async (data: ITaskDTO) => {
    const response = await TaskAPI.createTask(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const updateHiddenProperty = createAsyncThunk<
  any,
  IUpdateHiddenProperty,
  { serializedErrorType: IApiError }
>(
  `${TASK_SLICE_NAME}/updateHiddenProperty`,
  async (data: IUpdateHiddenProperty) => {
    const response = await TaskAPI.updateHiddenProperty(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const updateTask = createAsyncThunk<
  any,
  IUpdatetaskDTO,
  { serializedErrorType: IApiError }
>(
  `${TASK_SLICE_NAME}/updateTask`,
  async (data: IUpdatetaskDTO) => {
    const response = await TaskAPI.updateTask(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const getAllImportableProjects = createAsyncThunk<
  any,
  void,
  { serializedErrorType: IApiError }
>(`${TASK_SLICE_NAME}/getTemplates`, async () => {
  const response = await TaskAPI.getTemplates();
  return response.data;
});

export const importTask = createAsyncThunk<
  any,
  IImporttask,
  { serializedErrorType: IApiError }
>(`${TASK_SLICE_NAME}/importTask`, async (data: IImporttask) => {
  const response = await TaskAPI.importTask(data);
  return response.data;
});

export const updateView = createAsyncThunk<
  any,
  IUpdateView,
  { serializedErrorType: IApiError }
>(`${TASK_SLICE_NAME}/updateView`, async (data: IUpdateView) => {
  const response = await TaskAPI.updateView(data);
  return response.data;
});

export const getUserProperties = createAsyncThunk<
  any,
  any,
  { serializedErrorType: IApiError }
>(`${TASK_SLICE_NAME}/getUserProperties`, async (data: any) => {
  const response = await TaskAPI.getUserProperties(data);
  return response.data;
});

export const addUserProperties = createAsyncThunk<
  any,
  any,
  { serializedErrorType: IApiError }
>(`${TASK_SLICE_NAME}/addUserProperties`, async (data: any) => {
  const response = await TaskAPI.addUserProperties(data);
  return response.data;
});

export const updateUserProperties = createAsyncThunk<
  any,
  any,
  { serializedErrorType: IApiError }
>(`${TASK_SLICE_NAME}/updateUserProperties`, async (data: any) => {
  const response = await TaskAPI.updateUserProperties(data);
  return response.data;
});

export const saveTemplate = createAsyncThunk<
  any,
  ISaveTemplate,
  { serializedErrorType: IApiError }
>(`${TASK_SLICE_NAME}/saveTemplate`, async (data: ISaveTemplate) => {
  const response = await TaskAPI.saveTemplate(data);
  return response.data;
});

export const updateTemplate = createAsyncThunk<
  any,
  IUpdateTemplate,
  { serializedErrorType: IApiError }
>(`${TASK_SLICE_NAME}/updateTemplate`, async (data: IUpdateTemplate) => {
  const response = await TaskAPI.updateTemplate(data);
  return response.data;
});
