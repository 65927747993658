import {
  ICreateProperties,
  ICreateView,
  IDeletePropertyRequest,
  IGetAllViewsResponse,
  IGetTasksResponse,
  IImporttask,
  ISaveTemplate,
  IUpdateHiddenProperty,
  IUpdatePropertyRequest,
  IUpdateTemplate,
  IUpdateView,
  IViewTask,
} from "models/task.model";
import { ITaskDTO, IUpdatetaskDTO } from "redux/task/models";
import { ApiResponse, httpClient } from "shared/utils";

class TaskAPI {
  static getAllViews(moduleType: string): ApiResponse<IGetAllViewsResponse> {
    return httpClient.get<IGetAllViewsResponse>(
      `/view/user-views/${moduleType}`
    );
  }

  static getTasks(data: IViewTask): ApiResponse<IGetTasksResponse> {
    const { viewId, ...remainingData } = data;
    return httpClient.post<IGetTasksResponse>("/task/filter", remainingData);
  }

  static createProperties(data: ICreateProperties): ApiResponse<any> {
    return httpClient.post<any>("/task/property/create", data);
  }

  static updateProperty(data: IUpdatePropertyRequest): ApiResponse<any> {
    return httpClient.patch<any>("/task/property/update", data);
  }

  static deleteProperty(data: IDeletePropertyRequest): ApiResponse<any> {
    return httpClient.delete<any>("/task/property/delete", { data });
  }

  static createView(data: ICreateView): ApiResponse<any> {
    return httpClient.post<any>("/view/create", data);
  }

  static createTask(data: ITaskDTO): ApiResponse<any> {
    return httpClient.post<any>("/task/create", data);
  }

  static updateTask(data: IUpdatetaskDTO): ApiResponse<any> {
    const { id, rowIndex, value, columnId, fromRecord, ...remainingData } =
      data;

    return httpClient.put<any>(`/task/update/${id}`, remainingData);
  }

  static updateHiddenProperty(data: IUpdateHiddenProperty): ApiResponse<any> {
    return httpClient.patch<any>("/view/edit-hidden-properties", data);
  }

  static getAllImportableProjects(): ApiResponse<any> {
    return httpClient.get<any>("/view/user-projects");
  }

  static importTask(data: IImporttask): ApiResponse<any> {
    return httpClient.post<any>("/task/import-task", data);
  }

  static updateView(data: IUpdateView): ApiResponse<any> {
    return httpClient.put<any>("/view/edit", data);
  }

  static getUserProperties(data: any): ApiResponse<any> {
    return httpClient.get<any>(`/user-properties?userId=${data.userId}`);
  }

  static addUserProperties(data: any): ApiResponse<any> {
    return httpClient.put<any>(
      `/user-properties/update-property/${data._id}`,
      data
    );
  }

  static updateUserProperties(data: any): ApiResponse<any> {
    return httpClient.put<any>(`/user-properties/${data._id}`, data);
  }

  static saveTemplate(data: ISaveTemplate): ApiResponse<any> {
    return httpClient.post<any>("/task/save-template", data);
  }

  static getTemplates(): ApiResponse<any> {
    return httpClient.get<any>("/task/list-template");
  }

  static updateTemplate(data: IUpdateTemplate): ApiResponse<any> {
    const { id, ...remainingData } = data;
    return httpClient.post<any>(`/task/update-template/${id}`, remainingData);
  }
}

export default TaskAPI;
