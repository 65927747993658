import React from "react";
import { grey } from "./colors";
import { useTranslation } from "react-i18next";

// Define types for props
interface RelationshipProps {
  value: string | React.ReactNode; // Value can be a string or a React Node
  backgroundColor: string; // Background color for the component
  handleRemoveOption?: (label: string) => void; // Function to remove an option
  label?: string; // Label for the component
  isRemovable?: boolean; // Boolean to check if the component is removable
  handleOpenOption?: () => void; // Function to open an option
}

export default function Relationship({
  value,
  backgroundColor,
  handleRemoveOption,
  label,
  isRemovable = false,
  handleOpenOption,
}: RelationshipProps) {
  const { t } = useTranslation();

  return (
    <span
      style={{
        boxSizing: "border-box",
        backgroundColor: backgroundColor,
        color: grey(800),
        fontWeight: 400,
        padding: "2px 6px",
        borderRadius: 4,
        display: "inline-block",
      }}
    >
      <span onClick={handleOpenOption}>
        {typeof value === "string" ? t(value) : value}
      </span>

      {isRemovable && (
        <span
          className="remove-option ml-2"
          onClick={() => handleRemoveOption?.(label || "")}
        >
          &times;
        </span>
      )}
    </span>
  );
}
