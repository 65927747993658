import React, { useState } from "react";
import { Modal, Input, Button } from "antd";
import * as AntdIcons from "@ant-design/icons";

interface IconPickerProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectIcon: (icon: React.ComponentType, name: string) => void;
  iconModalPosition: {
    x: number;
    y: number;
  };
}

export function IconPicker({
  isOpen,
  onClose,
  onSelectIcon,
  iconModalPosition,
}: IconPickerProps) {
  const [searchTerm, setSearchTerm] = useState("");

  const iconEntries = Object.entries(AntdIcons).filter(
    ([name]) =>
      name.endsWith("Outlined") &&
      name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <Modal
        title="Choose an icon"
        visible={isOpen}
        onCancel={onClose}
        footer={null}
        width={500}
        style={{
          position: "absolute",
          left: iconModalPosition?.x,
          top: iconModalPosition?.y + 20,
        }}
        mask={false}
      >
        <Input
          placeholder="Search icons..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginBottom: 16 }}
        />
        <div style={{ height: 300, overflowY: "auto", scrollbarWidth: "none" }}>
          {iconEntries.map(([name, Icon]) => (
            <Button
              key={name}
              icon={<Icon />}
              onClick={() => {
                onSelectIcon(Icon as React.ComponentType, name);
                onClose();
              }}
              style={{ margin: "0 8px 8px 0" }}
            >
              {/* {name.replace("Outlined", "")} */}
            </Button>
          ))}
        </div>
      </Modal>
    </div>
  );
}
