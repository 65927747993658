import React from 'react';

export default function Filter() {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.60938 1.21875H12.6344M4.05937 6H10.1844M6.50937 10.7812H7.73437"
        stroke="#2D4764"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

