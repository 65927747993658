"use client";

import type React from "react";
import { useState } from "react";
import { Button } from "antd";
import type { Column } from "redux/task/models";
import type { FieldState } from "models/task.model";
import { EditableField } from "./EditableField";

interface DynamicFieldsProps {
  columns: Column[];
  fields: { [key: string]: FieldState };
  setFields: React.Dispatch<
    React.SetStateAction<{ [key: string]: FieldState }>
  >;
}

export const DynamicFields: React.FC<DynamicFieldsProps> = ({
  columns,
  fields,
  setFields,
}) => {
  const [showAllValues, setShowAllValues] = useState(false);

  const handleFieldChange = (fieldId: string, value: string) => {
    setFields((prevFields) => ({
      ...prevFields,
      [fieldId]: { ...prevFields[fieldId], value },
    }));
  };

  const handleFieldEdit = (fieldId: string, isEditing: boolean) => {
    setFields((prevFields) => ({
      ...prevFields,
      [fieldId]: { ...prevFields[fieldId], isEditing },
    }));
  };

  const handleFieldSave = async (fieldId: string) => {
    handleFieldEdit(fieldId, false);
  };

  return (
    <div>
      {columns.map((column) => {
        if (column.dataType === "null") return null;

        const field = fields[column.id];
        if (!field) return null;

        return (
          <EditableField
            key={column.id}
            column={column}
            field={field}
            onFieldChange={handleFieldChange}
            onFieldEdit={handleFieldEdit}
            onFieldSave={handleFieldSave}
          />
        );
      })}

      {!showAllValues && (
        <Button
          type="text"
          onClick={() => setShowAllValues(true)}
          style={{
            color: "#8c8c8c",
            paddingLeft: 24,
            height: "auto",
            marginTop: 8,
          }}
        >
          Show all values {">"}
        </Button>
      )}
    </div>
  );
};
