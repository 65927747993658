import { IApiError } from "models/apiError.model";
import { FieldType, ITask, IView } from "models/task.model";

export const TASK_SLICE_NAME = "task";
export interface TaskState {
  views: IView[];
  isLoading: boolean;
  error: IApiError | null;
  tasks: ITask[];
  activeTask: string | null;
  columns: Column[];
  data: Row[];
  filteredData: Row[];
  skipReset: boolean;
  activeFilters: FilterValue[];
  activeSorting: SortingValue | null;
  selectedOptionIndex: number;
  isMenuOpen: boolean;
  showFilters: boolean;
  showSorting: boolean;
  drawerVisible: boolean;
  miltiTaskDrawer: boolean;
  modalPosition: { x: number; y: number };
  isViewCreateModalOpen: boolean;
  recordDetailsDrawer: boolean;
  selectedTask: string | null;
  groupBy: string;
  activeLayout: string;
  hiddenProperties: string[];
  activeProject: string | null;
  activeSavedProject: any | null;
  importableProjects: any[];
  projectIsLoading: boolean;
  sortingProperties: string[];
  userProperties: any;
}

export interface FilterValue {
  column: string;
  value: string[];
}

export interface SortingValue {
  column: string;
  desc: boolean;
}

export const initialState: TaskState = {
  views: [],
  isLoading: false,
  error: null,
  selectedTask: null,
  tasks: [
    {
      _id: "6788a87d1b806df7fa90b608",
      viewId: "67889a8a1b806df7fa9087e9",
      taskName: "Testing secound task",
      name: {
        type: FieldType.Text,
        icon: "🔥",
        value: "gunvant",
      },
      surname: {
        type: FieldType.Text,
        icon: "😁",
        value: "panchal",
      },
      createdAt: "2025-01-16T06:34:37.263Z",
      updatedAt: "2025-01-16T06:34:37.263Z",
      __v: 0,
    },
  ],
  activeTask: null,
  columns: [],
  data: [],
  filteredData: [],
  skipReset: false,
  activeFilters: [],
  activeSorting: null,
  selectedOptionIndex: 0,
  miltiTaskDrawer: false,
  isMenuOpen: false,
  showFilters: true,
  drawerVisible: false,
  modalPosition: { x: 0, y: 0 },
  isViewCreateModalOpen: false,
  groupBy: "Status",
  activeLayout: "table",
  hiddenProperties: [],
  activeProject: null,
  recordDetailsDrawer: false,
  activeSavedProject: null,
  importableProjects: [],
  projectIsLoading: false,
  sortingProperties: [],
  userProperties: {},
};

export type Action =
  | {
      type: "add_option_to_column";
      columnId: string;
      option: string;
      backgroundColor: string;
    }
  | { type: "add_row" }
  | {
      type: "update_column_type";
      columnId: string;
      dataType: FieldType;
    }
  | { type: "update_column_header"; columnId: string; label: string }
  | { type: "update_cell"; rowIndex: number; columnId: string; value: any }
  | { type: "add_column_to_left"; columnId: string; focus?: boolean }
  | { type: "add_column_to_right"; columnId: string; focus?: boolean }
  | { type: "delete_column"; columnId: string }
  | { type: "enable_reset" }
  | { type: "update_state"; columns: any; data: any; skipreset: any };

export type Column = {
  id: string;
  label: string;
  icon: string;
  accessor: string;
  dataType: FieldType;
  created?: boolean;
  options: Array<{ label: string; backgroundColor: string }>;
};

export type Row = { [key: string]: any };

export type State = {
  skipReset: boolean;
  columns: Column[];
  data: Row[];
};
export interface ITaskResponse {
  data: {
    viewId: string;
    _id: string;
    taskName: string;
    tag: {
      type: string;
      icon: string;
      value: string;
    };
    name: {
      type: string;
      icon: string;
      value: string;
    };
    employee: {
      type: string;
      icon: string;
      value: string[];
    };
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
}

export interface ITaskDTO {
  viewId: string;
  projectId: string;
  taskName?: string;
  [key: string]: any;
}

export interface IUpdatetaskDTO {
  taskName?: string;
  [key: string]: any;
  id: string;
  fromRecord?: boolean;
}
