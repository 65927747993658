import { Card, Input } from "antd";
import { EditOutlined, CheckOutlined } from "@ant-design/icons";
import { TaskSet } from "models/task.model";
import { Button } from "shared/ui";
import { useState } from "react";
import { useAppDispatch } from "redux/store";
import {
  getAllImportableProjects,
  updateTemplate,
} from "redux/task/actionCreators";
import { useTranslation } from "react-i18next";

interface TaskSetCardProps {
  taskSet: TaskSet;
  handleAddTask: (id: string) => void;
}

export function TaskSetCard({ taskSet, handleAddTask }: TaskSetCardProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(taskSet.name);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    dispatch(updateTemplate({ id: taskSet._id, name }));
    dispatch(getAllImportableProjects());
  };

  return (
    <Card className="task-card transition-shadow cursor-pointer mr-5">
      <div className="space-y-2">
        <div className="inline-block py-1 rounded-full bg-[#FFD75D] text-base flex items-center">
          {isEditing ? (
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              onPressEnter={handleSaveClick}
              className="w-auto"
            />
          ) : (
            name
          )}
          {isEditing ? (
            <CheckOutlined
              className="mx-2 cursor-pointer"
              onClick={handleSaveClick}
            />
          ) : (
            <EditOutlined
              className="mx-2 cursor-pointer"
              onClick={handleEditClick}
            />
          )}
        </div>
        <div className="flex items-center justify-between">
          <h3 className="text-xl font-medium text-[#2D4764] h-20">
            {taskSet.quoteID?.mapboxAddress?.complete}
          </h3>
        </div>
        <div className="flex justify-between">
          <p className="text-gray-500">
            {taskSet.count} {t("tasks")}
          </p>
          <Button
            className="bg-primary"
            onClick={() => handleAddTask(taskSet.projectId)}
          >
            {t("Import")}
          </Button>
        </div>
      </div>
    </Card>
  );
}
