import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  DatePicker,
  Image,
  message,
  Select,
  Typography,
  Upload,
} from "antd";
import { useDispatch } from "react-redux";
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  UploadOutlined,
  HolderOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { usePopper } from "react-popper";
import { grey } from "../Table/colors";
import { Column } from "redux/task/models";
import { FieldState, FieldType } from "models/task.model";
import ContentEditable from "react-contenteditable";
import { BASE_API_URL } from "shared/constants/common";
import { useAppState } from "redux/store";
import {
  selectActiveFilters,
  selectActiveProject,
  selectActiveSorting,
  selectActiveTask,
  selectHiddenProperties,
  selectUserProperties,
} from "redux/task/selectors";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { randomColor } from "shared/utils/transformDataDynamic";
import Relationship from "../Table/Relationship";
import { ThreeDots } from "../Table/SelectCell";
import {
  addUserProperties,
  createProperties,
  deleteProperty,
  updateProperty,
  updateUserProperties,
  updateView,
} from "redux/task/actionCreators";
import { addOptionToColumn } from "redux/task/taskSlice";

import PlusIcon from "../Table/img/Plus";
import HideIcon from "../Table/img/Hide";
import FilterIconL from "../Table/img/Filter";
import TrashIcon from "../Table/img/Trash";

import * as AntdIcons from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { CheckSquareOutlined } from "@ant-design/icons";
import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  FilterIcon,
} from "lucide-react";
import { selectInstallerDetails } from "redux/user/selectors";
import { IconPicker } from "../Table/IconPicker";

const { Text } = Typography;

interface EditableFieldProps {
  column: Column;
  field: FieldState;
  initialValue: string | number | null;
  onFieldChange: (fieldId: string, value: string) => void;
  onFieldEdit: (fieldId: string, isEditing: boolean) => void;
  onFieldSave: (fieldId: string) => void;
}

interface TypeProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon: React.ReactNode;
  label: string;
}

interface ButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon: React.ReactNode;
  label: string;
}

const ContentEditableLocal = (props: any) => {
  const contentRef = useRef<any>(null);

  useEffect(() => {
    // Auto-focus on mount
    if (contentRef && contentRef.current) {
      contentRef?.current?.focus();
    }
  }, [contentRef]);

  return <ContentEditable innerRef={contentRef} {...props} />;
};

export const EditableField: React.FC<EditableFieldProps> = ({
  column,
  field,
  onFieldChange,
  initialValue,
  onFieldEdit,
  onFieldSave,
}) => {
  const dispatch = useDispatch();

  const [value, setValue] = useState({
    value: initialValue, // ✅ Use initialValue here
    update: false,
  });
  const userProperties = useAppState(selectUserProperties);
  const activeView = useAppState(selectActiveTask);
  const activeProject = useAppState(selectActiveProject);
  const hiddenPropeties = useAppState(selectHiddenProperties);
  const sorting = useAppState(selectActiveSorting);
  const filters = useAppState(selectActiveFilters);
  const installerDetails = useAppState(selectInstallerDetails);
  const [selectRef, setSelectRef] = useState<HTMLDivElement | null>(null);
  const [selectPop, setSelectPop] = useState<HTMLDivElement | null>(null);
  const [showSelect, setShowSelect] = useState(true);
  const [showAdd, setShowAdd] = useState(false);
  const [addSelectRef, setAddSelectRef] = useState<HTMLInputElement | null>(
    null
  );
  const [expanded, setExpanded] = useState<boolean>(column.created || false);

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const [header, setHeader] = useState<string>(column.label);

  const [statusList, setStatusList] = useState(
    column?.options.filter((data) => data.label && data.label?.trim() !== "")
  );

  const [iconPickerOpen, setIconPickerOpen] = useState(false);
  const [iconModalPosition, setIconModalPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  const { styles, attributes } = usePopper(selectRef, selectPop, {
    placement: "bottom-start",
    strategy: "fixed",
  });

  const { styles: stylesPoper, attributes: attributesPoper } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: "bottom",
      strategy: "absolute",
    }
  );

  const handleRemoveOption = (optionLabel: string) => {
    const newValue = (value.value as string)
      .split(", ")
      .filter((label) => label !== optionLabel)
      .join(", ");
    setValue({ value: newValue, update: true });
  };

  const [typeReferenceElement, setTypeReferenceElement] =
    useState<HTMLElement | null>(null);
  const [typePopperElement, setTypePopperElement] =
    useState<HTMLElement | null>(null);
  const [showType, setShowType] = useState<boolean>(false);
  const typePopper = usePopper(typeReferenceElement, typePopperElement, {
    placement: "right",
    strategy: "fixed",
  });

  const { t } = useTranslation();

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(statusList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // setStatusList(items);
  };

  useEffect(() => {
    if (userProperties != null || userProperties != undefined)
      setStatusList(
        (userProperties?.properties[column.id as any] || []).map((a: any) => ({
          ...a,
          label: a.value,
        }))
      );
  }, [setStatusList, userProperties]);

  const handleUpdateExistingProperty = (
    oldValue: string,
    newValue: string,
    selectedColor: string
  ) => {
    dispatch(
      updateUserProperties({
        _id: userProperties._id,
        userId: column.id,
        companyName: userProperties.companyName,
        update: {
          [column.id]: {
            value: oldValue,
            updatedValue: newValue,
            backgroundColor: selectedColor,
          },
        },
      })
    );
  };

  const handleOptionBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value.trim() !== "") {
      dispatch(
        addOptionToColumn({
          columnId: column.id,
          option: e.target.value,
          backgroundColor: randomColor(),
        })
      );
    }
    setShowAdd(false);
  };

  const handleOptionKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && e.currentTarget.value.trim() !== "") {
      dispatch(
        addUserProperties({
          _id: userProperties._id,
          companyName: userProperties.companyName,
          update: {
            [column.id]: [
              ...(Array.isArray(userProperties?.properties[column.id])
                ? userProperties?.properties[column.id]
                : []),
              {
                value: e.currentTarget.value,
                updatedValue: e.currentTarget.value,
                backgroundColor: randomColor(),
              },
            ],
          },
        })
      );

      setShowAdd(false);
    }
  };

  const handleAddOption = () => {
    setShowAdd(true);
  };

  const renderEditingField = () => {
    switch (column.dataType) {
      case FieldType.Text:
      case FieldType.Number:
      case FieldType.Url:
        return (
          <ContentEditableLocal
            html={(field.value && field.value.toString()) || ""}
            onChange={(e: any) => onFieldChange(column.id, e.target.value)}
            placeholder={`Set ${column.label}...`}
            onFocus={() => onFieldEdit(column.id, true)}
            onBlur={() => onFieldSave(column.id)}
            className="data-input"
            tagName="a"
            autoFocus
          />
        );
      case FieldType.Person:
      case FieldType.Select:
        // <Select
        //   value={field.value}
        //   onChange={(value) => onFieldChange(column.id, value)}
        //   style={{ width: "100%" }}
        // >
        {
          /* {statusList.map((option, index) => (
              <Select.Option key={index} value={option.label}>
                {option.label}
              </Select.Option>
            ))} */
        }
        {
          /* </Select> */
        }
        return (
          <>
            <div
              ref={setSelectRef}
              className="cell-padding d-flex cursor-pointer align-items-center flex-1"
              onClick={() => setShowSelect(true)}
            >
              {value.value ? (
                <div
                  style={{
                    backgroundColor:
                      userProperties?.properties[column.id]?.find(
                        (option) => option.value === value.value
                      )?.backgroundColor || "transparent",
                    padding: "4px 8px",
                    borderRadius: "4px",
                    display: "inline-block",
                  }}
                >
                  {value.value}
                </div>
              ) : (
                <div> - </div>
              )}
            </div>
            {showSelect && (
              <div className="overlay" onClick={() => setShowSelect(false)} />
            )}
            {showSelect && (
              <div
                className="shadow-5 bg-white border-radius-md absolute"
                ref={setSelectPop}
                {...attributes.popper}
                style={{
                  ...styles.popper,
                  zIndex: 4,
                  minWidth: 200,
                  maxWidth: 320,
                  padding: "0.75rem",
                }}
              >
                <div style={{ position: "relative" }}>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="s</DragDropContext>tatus">
                      {(provided) => (
                        <div
                          style={{ position: "relative" }}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {userProperties.properties?.[column.id]?.map(
                            (option, index) => (
                              <Draggable
                                key={`option-${option.value}-${index}`}
                                draggableId={`option-${option.value}-${index}`}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      display: "flex",
                                      padding: "8px 0",
                                      alignItems: "center",
                                      cursor: "move",
                                    }}
                                    className="hover:bg-gray-100"
                                  >
                                    <HolderOutlined
                                      style={{ marginRight: 8, color: "#999" }}
                                    />
                                    <div
                                      key={option.value}
                                      className="cursor-pointer"
                                      onClick={() => {
                                        setValue({
                                          value: option.value,
                                          update: true,
                                        });
                                        setShowSelect(false);
                                      }}
                                    >
                                      <Relationship
                                        value={option.value}
                                        backgroundColor={option.backgroundColor}
                                      />
                                    </div>
                                    <ThreeDots
                                      option={option}
                                      statusList={statusList}
                                      setStatusList={setStatusList}
                                      handleUpdateExistingProperty={
                                        handleUpdateExistingProperty
                                      }
                                      onDelete={(deletedOption) => {
                                        if (
                                          value.value === deletedOption.label ||
                                          value.value === deletedOption.value
                                        ) {
                                          setValue({ value: "", update: true });
                                        }

                                        dispatch(
                                          addUserProperties({
                                            _id: userProperties._id,
                                            companyName:
                                              userProperties.companyName,
                                            update: {
                                              [column.id]: [
                                                ...userProperties?.properties[
                                                  column.id
                                                ].filter(
                                                  (a: any) =>
                                                    deletedOption.value !==
                                                    a.value
                                                ),
                                              ],
                                            },
                                          })
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            )
                          )}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {showAdd && (
                    <div
                      style={{
                        marginRight: "0.5rem",
                        marginTop: "0.5rem",
                        width: 120,
                        padding: "2px 4px",
                        backgroundColor: grey(200),
                        borderRadius: 4,
                      }}
                    >
                      <input
                        type="text"
                        className="option-input"
                        onBlur={handleOptionBlur}
                        ref={setAddSelectRef}
                        onKeyDown={handleOptionKeyDown}
                      />
                    </div>
                  )}

                  <div
                    className="cursor-pointer"
                    style={{ marginRight: "0.5rem", marginTop: "0.5rem" }}
                    onClick={handleAddOption}
                  >
                    <Relationship
                      value={
                        <span className="svg-icon-sm svg-text">
                          <PlusIcon />
                        </span>
                      }
                      backgroundColor={grey(200) || "#212121"}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        );
      case FieldType.MultiSelect:
        return (
          <>
            <div
              ref={setSelectRef}
              className="cell-padding d-flex flex-col align-items-center justify-start gap-2 cursor-pointer"
            >
              {typeof value.value === "string" && value.value ? (
                value.value.split(", ").map((label) => (
                  <div key={label} className="d-flex align-items-center gap-1">
                    <Relationship
                      value={label}
                      backgroundColor={
                        statusList.find((a) => a?.label === value.value)
                          ?.backgroundColor || randomColor()
                      }
                      handleRemoveOption={handleRemoveOption}
                      label={label}
                      isRemovable={true}
                      handleOpenOption={() => {
                        setShowSelect(true);
                      }}
                    />
                  </div>
                ))
              ) : (
                <div
                  onClick={() => {
                    setShowSelect(true);
                  }}
                >
                  {" "}
                  -{" "}
                </div>
              )}
            </div>
            {showSelect && (
              <div className="overlay" onClick={() => setShowSelect(false)} />
            )}
            {showSelect && (
              <div
                className="shadow-5 bg-white border-radius-md"
                ref={setSelectPop}
                {...attributes.popper}
                style={{
                  ...styles.popper,
                  zIndex: 4,
                  minWidth: 200,
                  maxWidth: 320,
                  padding: "0.75rem",
                }}
              >
                <div style={{ position: "relative" }}>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="status">
                      {(provided) => (
                        <div
                          style={{ position: "relative" }}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {statusList
                            ?.filter((a) => a.label !== " ")
                            ?.map((option, index) => (
                              <Draggable
                                key={`option-${option.label}-${index}`}
                                draggableId={`option-${option.label}-${index}`}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      display: "flex",
                                      padding: "8px 0",
                                      alignItems: "center",
                                      cursor: "move",
                                    }}
                                  >
                                    <HolderOutlined
                                      style={{ marginRight: 8, color: "#999" }}
                                    />
                                    <div
                                      key={option.label}
                                      className="cursor-pointer"
                                      onClick={() => {
                                        const newValue = value.value
                                          ? `${value.value}, ${option.label}`
                                          : option.label;
                                        setValue({
                                          value: newValue,
                                          update: true,
                                        });
                                      }}
                                    >
                                      <Relationship
                                        value={option.label}
                                        backgroundColor={option.backgroundColor}
                                      />
                                    </div>
                                    <ThreeDots
                                      option={option}
                                      statusList={statusList}
                                      setStatusList={setStatusList}
                                      handleUpdateExistingProperty={
                                        handleUpdateExistingProperty
                                      }
                                      onDelete={(deletedOption) => {
                                        if (
                                          value.value === deletedOption.label
                                        ) {
                                          setValue({ value: "", update: true });
                                        }

                                        dispatch(
                                          addUserProperties({
                                            _id: userProperties._id,
                                            companyName:
                                              userProperties.companyName,
                                            update: {
                                              [id]: [
                                                ...userProperties?.properties[
                                                  id
                                                ].filter(
                                                  (a: any) =>
                                                    deletedOption.value !==
                                                    a.value
                                                ),
                                              ],
                                            },
                                          })
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {showAdd && (
                    <div
                      style={{
                        marginRight: "0.5rem",
                        marginTop: "0.5rem",
                        width: 120,
                        padding: "2px 4px",
                        backgroundColor: grey(200),
                        borderRadius: 4,
                      }}
                    >
                      <input
                        type="text"
                        className="option-input"
                        onBlur={handleOptionBlur}
                        ref={setAddSelectRef}
                        onKeyDown={handleOptionKeyDown}
                      />
                    </div>
                  )}
                  <div
                    className="cursor-pointer"
                    style={{ marginRight: "0.5rem", marginTop: "0.5rem" }}
                    onClick={handleAddOption}
                  >
                    <Relationship
                      value={
                        <span className="svg-icon-sm svg-text">
                          <PlusIcon />
                        </span>
                      }
                      backgroundColor={grey(200) || "#212121"}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        );
      case FieldType.Date:
        return (
          <DatePicker
            value={field.value ? moment(field.value) : null}
            onChange={(date) =>
              onFieldChange(column.id, date ? date.format("YYYY-MM-DD") : "")
            }
            style={{ width: "100%" }}
          />
        );
      case FieldType.Files:
        return (
          <Upload
            name="file_upload"
            action={`${BASE_API_URL}/api/media`}
            showUploadList={true}
            className="flex justify-center m-2"
            onChange={({ file }) => {
              if (file.status === "done") {
                onFieldChange(column.id, file.response.data?.[0]?.url);
              }
            }}
          >
            {field.value ? (
              <img
                src={String(field.value) || ""}
                className="w-12 h-12"
                alt="Uploaded file"
              />
            ) : (
              <Button icon={<UploadOutlined />}>Upload</Button>
            )}
          </Upload>
        );
      case FieldType.Checkbox:
        return (
          <Checkbox
            checked={field.value === "true"}
            onChange={(e) =>
              onFieldChange(column.id, e.target.checked.toString())
            }
          >
            {column.label}
          </Checkbox>
        );
      default:
        return null;
    }
  };

  const renderViewField = () => {
    if (column.dataType === FieldType.Checkbox) {
      return (
        <Checkbox checked={field.value === "true"} disabled>
          {column.label}
        </Checkbox>
      );
    } else if (column.dataType === FieldType.Files) {
      return (
        <Image
          src={field.value || "/placeholder.svg"}
          width={80}
          alt="File preview"
        />
      );
    } else if (column.dataType === FieldType.Date) {
      return (
        <Text style={{ color: "#262626" }}>
          {moment(field.value).format("YYYY-MM-DD")}
        </Text>
      );
    } else {
      return (
        <Text
          style={{ color: "#262626" }}
          onClick={() => onFieldEdit(column.id, true)}
        >
          {field.value || `Set ${column.label}...`}
        </Text>
      );
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHeader(e.target.value);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target !== inputRef) {
      return;
    }
    e.preventDefault();
    dispatch(
      updateProperty({
        oldPropertyKey: column.id,
        newProperty: {
          key: header,
          icon: column.icon,
          type: column.dataType,
        },
        projectId: activeProject || undefined,
      })
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      dispatch(
        updateProperty({
          oldPropertyKey: column.id,
          newProperty: {
            key: header,
            type: column.dataType,
          },
          projectId: activeProject || undefined,
        })
      );
      setExpanded(false);
    }
  };

  const updatePropertyHandler = (type: FieldType, icon: string) => {
    dispatch(
      updateProperty({
        oldPropertyKey: column.id,
        newProperty: {
          key: column.id,
          type,
          icon,
        },
        projectId: activeProject || undefined,
      })
    );
    setShowType(false);
    setExpanded(false);
  };

  const handleUpdateSorting = (order: string) => {
    if (activeView) {
      dispatch(
        updateView({
          id: activeView || "",
          updates: {
            sort: [
              ...sorting,
              {
                column: column.id,
                order,
              },
            ],
          },
        })
      );

      setExpanded(false);
    } else {
      message.error("Please select a view to apply filters");
    }
  };

  const handleUpdateView = () => {
    if (activeView) {
      dispatch(
        updateView({
          id: activeView || "",
          updates: {
            filters: [
              ...filters,
              {
                column: column.id,
                value: [],
                type: column.dataType || "text",
                icon: column.icon || "AlignLeftOutlined",
              },
            ],
          },
        })
      );

      setExpanded(false);
    } else {
      message.error("Please select a view to apply filters");
    }
  };

  const IconComponent: any =
    AntdIcons[column.icon] || AntdIcons["CheckOutlined"];

  const types: TypeProps[] = [
    {
      onClick: () => updatePropertyHandler(FieldType.Text, "AlignLeftOutlined"),
      icon: <AntdIcons.AlignLeftOutlined />,
      label: "Text",
    },
    {
      onClick: () => updatePropertyHandler(FieldType.Number, "NumberOutlined"),
      icon: <AntdIcons.NumberOutlined />,
      label: "Number",
    },
    {
      onClick: () => updatePropertyHandler(FieldType.Select, "SelectOutlined"),
      icon: <AntdIcons.SelectOutlined />,
      label: "Select",
    },
    {
      onClick: () =>
        updatePropertyHandler(FieldType.MultiSelect, "SelectOutlined"),
      icon: <AntdIcons.SelectOutlined />,
      label: "MultiSelect",
    },
    {
      onClick: () => updatePropertyHandler(FieldType.Url, "LinkOutlined"),
      icon: <AntdIcons.LinkOutlined />,
      label: "URL",
    },
    {
      onClick: () => updatePropertyHandler(FieldType.Date, "CalendarOutlined"),
      icon: <AntdIcons.CalendarOutlined />,
      label: "Date",
    },
    {
      onClick: () =>
        updatePropertyHandler(FieldType.Files, "CloudUploadOutlined"),
      icon: <AntdIcons.CloudUploadOutlined />,
      label: "Upload",
    },
    {
      onClick: () =>
        updatePropertyHandler(FieldType.Checkbox, "CheckSquareOutlined"),
      icon: <CheckSquareOutlined />,
      label: "Checkbox",
    },
    {
      onClick: () => updatePropertyHandler(FieldType.Button, "NumberOutlined"),
      icon: <AntdIcons.NumberOutlined />,
      label: "Button",
    },
    {
      onClick: () => updatePropertyHandler(FieldType.Person, "UserOutlined"),
      icon: <AntdIcons.UserOutlined />,
      label: "Person",
    },
  ];

  const buttons: ButtonProps[] = [
    {
      onClick: () => {
        // setSortBy([{ id: column.id, desc: false }]);
        handleUpdateSorting("ascending");
        setExpanded(false);
      },
      icon: <ArrowUpIcon />,
      label: "Sort ascending",
    },
    {
      onClick: () => {
        // setSortBy([{ id, desc: true }]);
        handleUpdateSorting("descending");
        setExpanded(false);
      },
      icon: <ArrowDownIcon />,
      label: "Sort descending",
    },
    {
      onClick: () => {
        dispatch(
          createProperties({
            columnId: column.id,
            property: {
              key: `new_${Date.now()}`,
              type: FieldType.Text,
              icon: "AlignLeftOutlined",
              value: "",
            },
            projectId: activeProject || undefined,
            companyName: installerDetails?.companyName || undefined,
          })
        );

        setExpanded(false);
      },
      icon: <ArrowLeftIcon />,
      label: "Insert left",
    },
    {
      onClick: () => {
        dispatch(
          createProperties({
            columnId: column.id,
            property: {
              key: `new_${Date.now()}`,
              type: FieldType.Text,
              icon: "AlignLeftOutlined",
              value: "",
            },
            projectId: activeProject || undefined,
            companyName: installerDetails?.companyName || undefined,
          })
        );

        setExpanded(false);
      },
      icon: <ArrowRightIcon />,
      label: "Insert right",
    },
    {
      onClick: handleUpdateView,
      icon: <FilterIconL />,
      label: "Filter",
    },
    {
      onClick: () => {
        if (activeView) {
          dispatch(
            updateView({
              id: activeView,
              updates: { hiddenProperties: [...hiddenPropeties, id] },
            })
          );
        } else {
          message.info("Please select a view to hide/unhide properties");
        }
        setExpanded(false);
      },
      icon: <HideIcon />,
      label: "hide in view",
    },
    {
      onClick: () => {
        dispatch(
          deleteProperty({
            propertyKey: column.id,
            viewId: activeProject || "",
          })
        );
        setExpanded(false);
      },
      icon: <TrashIcon />,
      label: "Delete",
    },
  ];

  const handleSelect = (selectedIcon: string) => {
    dispatch(
      updateProperty({
        oldPropertyKey: column.id,
        newProperty: {
          key: column.id,
          type: column.dataType,
          icon: selectedIcon,
        },
        projectId: activeProject || undefined,
      })
    );
    setExpanded(false);
  };

  return (
    <div className="mb-4 flex gap-4">
      <div
        className="flex items-center mb-1 gap-2 text-[#8c8c8c]"
        style={{ width: "40%" }}
      >
        {/* <IconComponent /> */}
        {/* <Text>{column.label}</Text> */}

        <div
          className="th-content flex gap-1 items-center justify-start cursor-pointer"
          onClick={() => setExpanded(true)}
          ref={setReferenceElement}
        >
          <div className="flex items-center svg-icon svg-gray icon-margin">
            {/* {React.createElement(AntdIcons[column?.icon || 'CheckOutlined'])} */}
            <IconComponent />
          </div>
          <div> {t(column.label)}</div>
        </div>
        {expanded && (
          <div className="overlay" onClick={() => setExpanded(false)} />
        )}
        {expanded && (
          <div
            ref={setPopperElement}
            style={{ ...stylesPoper.popper, zIndex: 3 }}
            {...attributesPoper.popper}
          >
            <div
              className="bg-white shadow-5 border-radius-md"
              style={{
                width: 240,
              }}
            >
              <div
                style={{
                  paddingTop: "0.75rem",
                  paddingLeft: "0.75rem",
                  paddingRight: "0.75rem",
                }}
              >
                <div
                  className="is-fullwidth flex gap-3"
                  style={{ marginBottom: 12 }}
                >
                  <Button
                    icon={React.createElement(
                      AntdIcons[column.icon] || AntdIcons["CheckOutlined"]
                    )}
                    onClick={(e) => {
                      const rect = e.currentTarget.getBoundingClientRect();
                      setIconModalPosition({ x: rect.left, y: rect.bottom });
                      setIconPickerOpen(true);
                    }}
                  />

                  <input
                    className="form-input"
                    ref={setInputRef}
                    type="text"
                    value={header}
                    style={{ width: "100%" }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <span
                  className="font-weight-600 font-size-75"
                  style={{ textTransform: "uppercase", color: grey(500) }}
                >
                  {t("Property Type")}
                </span>
              </div>
              <div style={{ padding: "4px 0px" }}>
                <button
                  className="sort-button"
                  type="button"
                  onMouseEnter={() => setShowType(true)}
                  onMouseLeave={() => setShowType(false)}
                  ref={setTypeReferenceElement}
                >
                  <span className="svg-icon svg-text icon-margin">
                    {React.createElement(
                      AntdIcons[column.icon] || AntdIcons["CheckOutlined"]
                    )}
                  </span>
                  <span style={{ textTransform: "capitalize" }}>
                    {column.dataType}
                  </span>
                </button>
                {showType && (
                  <div
                    className="shadow-5 bg-white border-radius-m"
                    ref={setTypePopperElement}
                    onMouseEnter={() => setShowType(true)}
                    onMouseLeave={() => setShowType(false)}
                    {...typePopper.attributes.popper}
                    style={{
                      ...typePopper.styles.popper,
                      width: 200,
                      backgroundColor: "white",
                      zIndex: 4,
                      padding: "4px 0px",
                    }}
                  >
                    {types.map((type) => (
                      <button className="sort-button" onClick={type.onClick}>
                        <span className="svg-icon svg-text icon-margin">
                          {type.icon}
                        </span>
                        {t(type.label)}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              <div
                style={{
                  borderTop: `2px solid ${grey(200)}`,
                  padding: "4px 0px",
                }}
              >
                {buttons.map((button) => (
                  <button
                    type="button"
                    className="sort-button"
                    onMouseDown={button.onClick}
                  >
                    <span className="svg-icon svg-text icon-margin">
                      {button.icon}
                    </span>
                    {t(button.label)}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
        {iconPickerOpen && (
          <IconPicker
            isOpen={iconPickerOpen}
            onClose={() => setIconPickerOpen(false)}
            onSelectIcon={(Icon, name) => handleSelect(name)}
            iconModalPosition={iconModalPosition}
          />
        )}
      </div>
      <div style={{ width: "50%", maxWidth: "200px" }}>
        {field.isEditing ? (
          <div className="flex items-center gap-2 ml-6 ">
            {renderEditingField()}
            {/* <Button
              type="text"
              icon={<CheckOutlined style={{ color: "#1890ff" }} />}
              onClick={() => onFieldSave(column.id)}
            />
            <Button
              type="text"
              icon={<CloseOutlined style={{ color: "#8c8c8c" }} />}
              onClick={() => onFieldEdit(column.id, false)}
            /> */}
          </div>
        ) : (
          <div className="flex items-center ml-6" style={{ cursor: "pointer" }}>
            {renderViewField()}
            {/* {column.dataType !== FieldType.Button && (
              <Button
                type="text"
                icon={<EditOutlined style={{ color: "#8c8c8c" }} />}
                onClick={() => onFieldEdit(column.id, true)}
                style={{ marginLeft: 8 }}
              />
            )} */}
          </div>
        )}
      </div>
    </div>
  );
};
