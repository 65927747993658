import { IApiError } from "models/apiError.model";
import { IInstaller } from "models/installer.model";
import { IConstant, IUserDetails } from "models/user.model";

export const USER_SLICE_NAME = "user";
export interface UserState {
  isAuthorized: boolean;
  user: IUserDetails | null;
  installerDetails: IInstaller | null;
  isLoading: boolean;
  error: IApiError | null;
  freeDownloadCount: number;
  properties: any[];
  constants: IConstant[] | null;
  solarPanels: any | null;
  users: any[];
}

export const initialState: UserState = {
  isAuthorized: false,
  user: null,
  installerDetails: null,
  isLoading: false,
  error: null,
  freeDownloadCount: 0,
  properties: [],
  constants: null,
  solarPanels: null,
  users: [],
};
