import type React from "react";
import { useState, useEffect } from "react";
import { Button, Divider } from "antd";
import { FullscreenOutlined, FullscreenExitOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppState } from "redux/store";
import {
  selectActiveProject,
  selectActiveTask,
  selectColumns,
  selectFilteredData,
  selectSelectedTask,
} from "redux/task/selectors";
import { setDrawerVisible } from "redux/task/taskSlice";
import { createTask, updateTask } from "redux/task/actionCreators";
import { transformData } from "shared/utils/transformDataDynamic";
import type { RecordDetailsProps, FieldState } from "models/task.model";
import { DynamicFields } from "./DynamicFields";
import { ResizableDrawer } from "./ResizableDrawer";
import AddList from "../Table/AddList";
import { selectInstallerDetails } from "redux/user/selectors";
import { useTranslation } from "react-i18next";
export const RecordDetails: React.FC<RecordDetailsProps> = ({ visible }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [fields, setFields] = useState<{ [key: string]: FieldState }>({});
  const userDetails = useAppState(selectInstallerDetails);
  const { t } = useTranslation();

  const columns = useAppState(selectColumns);
  const activeView = useAppState(selectActiveTask);
  const selectedTask = useAppState(selectSelectedTask);
  const activeProject = useAppState(selectActiveProject);
  const data = useAppState(selectFilteredData);
  const dispatch = useAppDispatch();

  const handleCloseDrawer = () => {
    dispatch(setDrawerVisible(false));
  };

  useEffect(() => {
    const currentData = data?.find((a) => a?._id === selectedTask);
    const initialFields = columns.reduce((acc, column) => {
      if (column.dataType !== "null") {
        acc[column.id] = {
          value: currentData?.[column.id] || "",
          isEditing: false,
        };
      }
      return acc;
    }, {});
    setFields(initialFields);
  }, [selectedTask, columns, data]);

  const handleCreateTask = () => {
    const taskData = transformData({
      projectId: activeProject,
      viewId: activeView,
      data: columns.slice(0, -1),
      preData: fields,
      id: selectedTask,
      companyName: userDetails?.companyName,
    });

    if (selectedTask) {
      dispatch(updateTask(taskData));
    } else {
      dispatch(createTask(taskData));
    }

    dispatch(setDrawerVisible(false));
  };

  return (
    <ResizableDrawer
      visible={visible}
      onClose={handleCloseDrawer}
      isFullScreen={isFullScreen}
      setIsFullScreen={setIsFullScreen}
    >
      <div className="p-4 pt-8 w-full">
        <DynamicFields
          columns={columns}
          fields={fields}
          setFields={setFields}
        />

        <Divider style={{ borderColor: "#f0f0f0", margin: "24px 0" }} />
        <AddList />
      </div>
      <Button
        type="primary"
        className="flex mx-auto"
        onClick={handleCreateTask}
      >
        {!selectedTask ? t("Create Task") : t("Edit Task")}
      </Button>
    </ResizableDrawer>
  );
};
