import React from "react";

export default function Hide() {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5926 10.418C9.59545 11.1781 8.38125 11.5992 7.1276 11.6197C3.04427 11.6197 0.710938 6.95304 0.710938 6.95304C1.43654 5.60082 2.44293 4.4194 3.6626 3.48804M5.9026 2.42638C6.30413 2.33239 6.71523 2.28541 7.1276 2.28638C11.2109 2.28638 13.5443 6.95304 13.5443 6.95304C13.1902 7.61548 12.7679 8.23914 12.2843 8.81388M8.36427 8.18971C8.20406 8.36165 8.01086 8.49955 7.79619 8.5952C7.58153 8.69085 7.3498 8.74228 7.11482 8.74642C6.87985 8.75057 6.64645 8.70735 6.42854 8.61933C6.21064 8.53131 6.01269 8.40031 5.84651 8.23413C5.68034 8.06796 5.54933 7.87001 5.46132 7.6521C5.3733 7.4342 5.33008 7.2008 5.33422 6.96582C5.33837 6.73085 5.3898 6.49912 5.48545 6.28446C5.5811 6.06979 5.719 5.87659 5.89094 5.71638M0.710938 0.536377L13.5443 13.3697"
        stroke="#2D4764"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
