import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  initialState,
  TASK_SLICE_NAME,
  TaskState,
  Column,
  Row,
  FilterValue,
} from "./models";
import {
  addUserProperties,
  createProperties,
  createTask,
  createView,
  deleteProperty,
  getAllImportableProjects,
  getAllViews,
  getTasks,
  getUserProperties,
  importTask,
  updateHiddenProperty,
  updateProperty,
  updateTask,
  updateUserProperties,
  updateView,
} from "./actionCreators";
import {
  transformDataDynamic,
  transformReduxData,
} from "shared/utils/transformDataDynamic";
import { FieldType } from "models/task.model";
import { getAllSavedProject } from "redux/savedProject/actionCreators";
import { message } from "antd";

const taskSlice = createSlice({
  name: TASK_SLICE_NAME,
  initialState,
  reducers: {
    setRecordDetailsDrawer(state, action) {
      state.activeTask = action.payload;
    },
    setActiveTask(state, action) {
      state.activeTask = action.payload;
    },
    setSelectedTask(state, action) {
      state.selectedTask = action.payload;
    },
    setActiveLayout(state, action) {
      state.activeLayout = action.payload;
    },
    setGroupBy(state, action) {
      state.groupBy = action.payload;
    },
    setHiddenProperties(state, action) {
      state.hiddenProperties = action.payload;
    },
    setSortingProperties(state, action) {
      state.sortingProperties = action.payload;
    },
    addOptionToColumn(
      state,
      action: PayloadAction<{
        columnId: string;
        option: string;
        backgroundColor: string;
      }>
    ) {
      const { columnId, option, backgroundColor } = action.payload;
      const column = state.columns.find((col) => col.id === columnId);
      if (column) {
        column.options.push({ label: option, backgroundColor });
      }
    },
    addRow(state) {
      state.data.push({});
    },
    updateColumnType(
      state,
      action: PayloadAction<{
        columnId: string;
        dataType: FieldType;
      }>
    ) {
      const { columnId, dataType } = action.payload;
      const column = state.columns.find((col) => col.id === columnId);
      if (column && column.dataType !== dataType) {
        column.dataType = dataType;
      }
    },
    updateColumnHeader(
      state,
      action: PayloadAction<{ columnId: string; label: string }>
    ) {
      const { columnId, label } = action.payload;
      const column = state.columns.find((col) => col.id === columnId);
      if (column) {
        column.label = label;
      }
    },
    updateCell(
      state,
      action: PayloadAction<{ rowIndex: number; columnId: string; value: any }>
    ) {
      const { rowIndex, columnId, value } = action.payload;
      state.data[rowIndex][columnId] = value;
    },
    addColumnToLeft(
      state,
      action: PayloadAction<{ columnId: string; focus?: boolean }>
    ) {
      const { columnId } = action.payload;
      const index = state.columns.findIndex((col) => col.id === columnId);
      if (index !== -1) {
        state.columns.splice(index, 0, {
          id: `new_${Date.now()}`,
          label: "",
          accessor: "",
          icon: "",
          dataType: FieldType.Text,
          created: true,
          options: [],
        });
      }
    },
    addColumnToRight(
      state,
      action: PayloadAction<{ columnId: string; focus?: boolean }>
    ) {
      const { columnId } = action.payload;
      const index = state.columns.findIndex((col) => col.id === columnId);
      if (index !== -1) {
        state.columns.splice(index + 1, 0, {
          id: `new_${Date.now()}`,
          label: "",
          accessor: "",
          icon: "",
          dataType: FieldType.Text,
          created: true,
          options: [],
        });
      }
    },
    deleteColumn(state, action: PayloadAction<{ columnId: string }>) {
      const { columnId } = action.payload;
      state.columns = state.columns.filter((col) => col.id !== columnId);
    },
    enableReset(state) {
      state.skipReset = false;
    },
    updateState(
      state,
      action: PayloadAction<{
        columns: Column[];
        data: Row[];
        skipReset: boolean;
      }>
    ) {
      const { columns, data, skipReset } = action.payload;
      state.columns = columns;
      state.data = data;
      state.skipReset = skipReset;
    },
    setSelectedOptionIndex(state, action: PayloadAction<number>) {
      state.selectedOptionIndex = action.payload;
    },
    setIsMenuOpen(state, action: PayloadAction<boolean>) {
      state.isMenuOpen = action.payload;
    },
    setShowFilters(state, action: PayloadAction<boolean>) {
      state.showFilters = action.payload;
    },
    setDrawerVisible(state, action: PayloadAction<boolean>) {
      state.drawerVisible = action.payload;
      state.selectedTask = null;
    },
    setMultTaskDrawer(state, action: PayloadAction<boolean>) {
      state.miltiTaskDrawer = action.payload;
    },
    setActiveFilters(state, action: PayloadAction<FilterValue[]>) {
      state.activeFilters = action.payload;

      // Apply filters to data
      if (action.payload.length > 0) {
        const filteredData = state.data.filter((row) => {
          // Check if the row matches all active filters
          return action.payload.every((filter) => {
            // If the column doesn't exist in this row or the filter has no values, skip this filter
            if (!row[filter.column] || filter.value.length === 0) return true;

            // Check if any of the filter values match the row value
            // For string values, check for includes
            if (typeof row[filter.column] === "string") {
              return filter.value.some((val) =>
                row[filter.column].toLowerCase().includes(val.toLowerCase())
              );
            }
            // For array values, check for intersection
            else if (Array.isArray(row[filter.column])) {
              return filter.value.some((val) =>
                row[filter.column].includes(val)
              );
            }
            // For objects with a value property (like from the task model)
            else if (
              row[filter.column] &&
              typeof row[filter.column] === "object" &&
              "value" in row[filter.column]
            ) {
              return filter.value.some((val) =>
                String(row[filter.column].value)
                  .toLowerCase()
                  .includes(val.toLowerCase())
              );
            }

            // Direct comparison for other value types
            return filter.value.includes(String(row[filter.column]));
          });
        });

        state.filteredData = filteredData;
      }

      if (action.payload.length === 0) {
        console.log("hitted");
        state.filteredData = state.data;
      }
    },
    setActiveSorting(
      state,
      action: PayloadAction<{ column: string; desc: boolean } | null>
    ) {
      state.activeSorting = action.payload;
    },
    setModalPosition(state, action: PayloadAction<{ x: number; y: number }>) {
      state.modalPosition = action.payload;
    },
    removeFilter(state, action: PayloadAction<number>) {
      state.activeFilters = state.activeFilters.filter(
        (_, i) => i !== action.payload
      );
    },
    addFilter(state, action: PayloadAction<string>) {
      state.activeFilters.push({ column: action.payload, value: [] });
    },
    setViewCreate(state, action: PayloadAction<boolean>) {
      state.isViewCreateModalOpen = action.payload;
    },
    updateFilter(
      state,
      action: PayloadAction<{ index: number; newValue: string | string[] }>
    ) {
      const { index, newValue } = action.payload;
      state.activeFilters[index].value = Array.isArray(newValue)
        ? newValue
        : [newValue];
    },
    setActiveProject(state, action: PayloadAction<string>) {
      state.activeProject = action.payload;
      // state.columns = [];
      // state.data = [];
      // state.filteredData = [];
    },
    setSavedProject(state, action: PayloadAction<any>) {
      state.activeSavedProject = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isFulfilled(getAllViews), (state: TaskState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.views = payload.data;
      })
      .addMatcher(isFulfilled(getTasks), (state: TaskState, action) => {
        const { meta, payload } = action;
        state.projectIsLoading = false;
        const transformedData = transformDataDynamic(payload.data);
        state.columns = transformedData.columns;
        state.data = transformedData.data;
        state.filteredData = transformedData.data;
      })
      .addMatcher(isFulfilled(updateProperty), (state: TaskState, action) => {
        const { oldPropertyKey, newProperty } = action.meta.arg;
        const column = state.columns.find((col) => col.id === oldPropertyKey);
        if (column) {
          column.label = newProperty.key;
          column.accessor = newProperty.key;
          column.id = newProperty.key;
          column.created = false;

          if (newProperty.icon) {
            column.icon = newProperty.icon;
          }

          if (newProperty.type) {
            column.dataType = newProperty.type;
          }
          message.success("Eigenschaft erfolgreich aktualisiert");
        }
      })
      .addMatcher(isRejected(updateProperty), (state: TaskState, action) => {
        const { oldPropertyKey, newProperty } = action.meta.arg;
        const column = state.columns.find((col) => col.id === oldPropertyKey);
        if (column) {
          column.label = newProperty.key;
          column.accessor = newProperty.key;
          column.id = newProperty.key;

          if (newProperty.icon) {
            column.icon = newProperty.icon;
          }

          if (newProperty.type) {
            column.dataType = newProperty.type;
          }
        }
      })
      .addMatcher(isFulfilled(deleteProperty), (state: TaskState, action) => {
        const { propertyKey } = action.meta.arg;
        state.columns = state.columns.filter((col) => col.id !== propertyKey);
      })
      .addMatcher(isFulfilled(createProperties), (state: TaskState, action) => {
        const { property, columnId } = action.meta.arg;

        const index = state.columns.findIndex((col) => col.id === columnId);
        if (index !== -1) {
          state.columns.splice(index, 0, {
            id: property.key,
            label: property.key,
            accessor: property.key,
            dataType: property.type,
            icon: property.icon,
            created: true,
            options: [],
          });
        }

        state.isLoading = false;
        message.success("Eigenschaft erfolgreich erstellt");
      })
      .addMatcher(isFulfilled(createView), (state: TaskState, action) => {
        const { payload } = action;
        state.isViewCreateModalOpen = false;
        state.isLoading = false;
        message.success(payload.message);
      })
      .addMatcher(
        isFulfilled(getAllImportableProjects),
        (state: TaskState, action) => {
          const { payload } = action;
          state.isLoading = false;
          state.importableProjects = payload.data;
        }
      )
      .addMatcher(isFulfilled(importTask), (state: TaskState, action) => {
        state.isLoading = false;
        state.miltiTaskDrawer = false;
      })
      .addMatcher(isFulfilled(createTask), (state: TaskState, action) => {
        const newTask = action.payload;
        state.filteredData.push(transformReduxData(newTask.data));
      })
      .addMatcher(isFulfilled(updateTask), (state: TaskState, action) => {
        const { id, fromRecord } = action.meta.arg;
        if (!fromRecord) {
          const { rowIndex, columnId, value } = action.meta.arg;
          state.filteredData[rowIndex][columnId] = value;
        } else {
          state.filteredData = state.filteredData.map((q) =>
            q._id === id
              ? { ...q, ...transformReduxData(action.payload.data) }
              : q
          );
        }
      })
      .addMatcher(
        isRejected(getTasks, getAllSavedProject),
        (state: TaskState, action) => {
          const { error, meta } = action;
          state.error = error;
          state.projectIsLoading = false;
        }
      )
      .addMatcher(
        isPending(getTasks, getAllSavedProject),
        (state: TaskState) => {
          state.error = null;
          state.projectIsLoading = true;
        }
      )
      .addMatcher(
        isFulfilled(getUserProperties),
        (state: TaskState, action) => {
          const { payload } = action;
          state.isLoading = false;
          state.userProperties = payload.data[0];
        }
      )
      .addMatcher(
        isFulfilled(addUserProperties),
        (state: TaskState, action) => {
          const { payload } = action;
          state.isLoading = false;
          state.userProperties = payload.data.data;
        }
      )
      .addMatcher(
        isFulfilled(updateUserProperties),
        (state: TaskState, action) => {
          const { payload } = action;
          state.isLoading = false;
          state.userProperties = payload.data;
        }
      )
      .addMatcher(
        isRejected(
          getAllViews,
          getTasks,
          createProperties,
          createView,
          createTask,
          updateTask,
          deleteProperty,
          getAllSavedProject,
          getAllImportableProjects,
          importTask,
          updateHiddenProperty
        ),
        (state: TaskState, action) => {
          const { error } = action;
          state.error = error;
          state.isLoading = false;
          message.error(error.message || "Something went wrong");
        }
      )
      .addMatcher(
        isPending(
          getAllViews,
          getTasks,
          updateProperty,
          createProperties,
          createView,
          createTask,
          updateTask,
          deleteProperty,
          getAllSavedProject,
          getAllImportableProjects,
          importTask,
          updateHiddenProperty
        ),
        (state: TaskState) => {
          state.error = null;
          state.isLoading = true;
        }
      )
      .addMatcher(
        isFulfilled(getAllSavedProject),
        (state: TaskState, action) => {
          const { meta, payload } = action;
          state.projectIsLoading = false;
          if (!meta.arg?.fromView) {
            const transformedData = transformDataDynamic(
              payload.data.docs,
              true
            );
            state.columns = transformedData.columns;
            state.data = transformedData.data;
            state.filteredData = transformedData.data;

            state.hiddenProperties = [
              "roofType",
              "roofTilt",
              "consumption",
              "rating",
            ];
          }
        }
      )
      .addMatcher(isFulfilled(updateView), (state: TaskState, action) => {
        const { payload } = action;
        state.isLoading = false;
        console.log(state.views, payload, "ok");
        state.views = state.views?.map((view) => {
          if (view._id === payload.data._id) {
            return payload.data;
          }
          return view;
        });
      });
  },
});
export const {
  addOptionToColumn,
  addRow,
  updateColumnType,
  updateColumnHeader,
  updateCell,
  addColumnToLeft,
  addColumnToRight,
  deleteColumn,
  enableReset,
  updateState,
  setSelectedOptionIndex,
  setIsMenuOpen,
  setShowFilters,
  setDrawerVisible,
  setActiveFilters,
  setModalPosition,
  removeFilter,
  addFilter,
  updateFilter,
  setViewCreate,
  setActiveTask,
  setSelectedTask,
  setGroupBy,
  setMultTaskDrawer,
  setActiveLayout,
  setHiddenProperties,
  setActiveProject,
  setSavedProject,
  setSortingProperties,
  setActiveSorting,
} = taskSlice.actions;
export default taskSlice.reducer;
