export { default as httpClient, isHttpClientError } from "./httpClient";
export { default as formatApiError } from "./formatApiError";

export type { ApiResponse } from "./httpClient";
export { isValidURL } from "./urlChecker";

export function parseNumberString(str: string) {
  // Remove commas and dots from the string
  const cleanStr = str.replace(/[,.]/g, "");
  // Parse the cleaned string to a number
  const numberValue = parseFloat(cleanStr.replace(",", ".")); // Replace ',' with '.' for proper parsing in locales where ',' is used as decimal separator
  return isNaN(numberValue) ? 0 : numberValue; // Return 0 if parsing fails
}

const months = [
  "Jan",
  "Feb",
  "Mär",
  "Apr",
  "Mai",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Dez",
];

function convertMonths(monthsArray: any) {
  const fullMonthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Augest",
    "September",
    "Octomber",
    "November",
    "December",
  ];

  const convertedMonths = monthsArray.map(
    (monthAbbreviation: any, index: any) => ({
      key: monthAbbreviation,
      name: `${fullMonthNames[index]} (kWh)`,
    })
  );

  return convertedMonths;
}

export const convertedMonthsArray = convertMonths(months);

export function convertObject(inputObject: any) {
  const { moduleQuantity, productionCapacity, finalPrice, Jahr, ...monthData } =
    inputObject;

  const convertedMonths = convertMonths(Object.keys(monthData));
  const convertedObject: any = {
    moduleQuantity,
    productionCapacity: Number(String(productionCapacity).replaceAll(",", "")),
    finalPrice: finalPrice,
    yearlyProduction: Jahr,
    monthlySolarData: {},
  };

  convertedMonths.forEach(({ key, name }: any, index: any) => {
    if (key in monthData) {
      convertedObject.monthlySolarData[name] = monthData[key];
    }
  });

  return convertedObject;
}

export const getTitle = (status: string) => {
  switch (status) {
    case "APPROVAL_PENDING":
      return "Approval pending";
    case "REWORK_PENDING":
      return "Rework pending";
    case "VERIFIED":
      return "Verified";
    case "TO_BE_PLANNED":
      return "To be Planned";
    case "PRIORITY_PLANNING":
      return "Approval pending";
    case "URGENT_PLANNING":
      return "Urgent Planning";
    case "INSTALLER_REQUESTED":
      return "Installer Requested";
    case "IMAGE_UPLOADED":
      return "Image Uploaded";
    default:
      return "";
  }
};
